import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const BoatImageShowcase = ({ 
  boat_1, 
  boat_1_alt,
  boat_2, 
  boat_2__alt,
  boat_3, 
  boat_3_alt,
  boat_4, 
  boat_4_alt,
  boat_5, 
  boat_5_alt 
}) => {
  const [currentImage, setCurrentImage] = useState({ src: boat_1, alt: boat_1_alt });

  const handleThumbnailClick = (src, alt) => {
    setCurrentImage({ src, alt });
  };

  return (
    <div className="boat-product-imgs">
      <div className="boat-img-display">
        <div className="boat-img-showcase">
          <img src={currentImage.src} alt={currentImage.alt} id="current-image" />
        </div>
      </div>
      <div className="boat-img-select">
        <div className="boat-img-item">
          <Link to="#" onClick={() => handleThumbnailClick(boat_2, boat_2__alt)} data-id="1">
            <img src={boat_2} alt={boat_2__alt} />
          </Link>
        </div>
        <div className="boat-img-item">
          <Link to="#" onClick={() => handleThumbnailClick(boat_3, boat_3_alt)} data-id="2">
            <img src={boat_3} alt={boat_3_alt} />
          </Link>
        </div>
        <div className="boat-img-item">
          <Link to="#" onClick={() => handleThumbnailClick(boat_4, boat_4_alt)} data-id="3">
            <img src={boat_4} alt={boat_4_alt} />
          </Link>
        </div>
        <div className="boat-img-item">
          <Link to="#" onClick={() => handleThumbnailClick(boat_5, boat_5_alt)} data-id="4">
            <img src={boat_5} alt={boat_5_alt} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BoatImageShowcase;
