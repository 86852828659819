import React from 'react';
import { Helmet } from 'react-helmet';
import '../assets/css/yacht_styles.css';
import favicon from '../assets/images/favicon.webp';
import { Link } from 'react-router-dom';
import Header from '../Header';
import ScrollArrows from '../ScrollArrows';
import yacht_44ft from'../assets/images/yacht-images/44ft-dubai-yacht-dubai-marina-yacht-rental/44ft-dubai-yacht-dubai-marina-yacht-rental.webp';
import yacht_44ft_bedroom from'../assets/images/yacht-images/44ft-dubai-yacht-dubai-marina-yacht-rental/44ft-yacht-bedroom.webp';
import yacht_44ft_double_bed from'../assets/images/yacht-images/44ft-dubai-yacht-dubai-marina-yacht-rental/44ft-yacht-double-bed.webp';
import yacht_44ft_kitchen from'../assets/images/yacht-images/44ft-dubai-yacht-dubai-marina-yacht-rental/44ft-yacht-kitchen.webp';
import yacht_44ft_dining from'../assets/images/yacht-images/44ft-dubai-yacht-dubai-marina-yacht-rental/44ft-yacht-outer-dining.webp';
import yacht_44ft_hall from'../assets/images/yacht-images/44ft-dubai-yacht-dubai-marina-yacht-rental/44ft-yacht-hall.webp';
import YachtImageShowcase from './YachtImageShowcase';
import Footer from '../Footer';







function Yacht44ft() {
  const hoverImages = [
    { src: yacht_44ft, alt: "44ft yacht in dubai" },
    { src: yacht_44ft_bedroom, alt: "44ft yacht bedroom in dubai" },
    { src: yacht_44ft_double_bed, alt: "44ft yacht bedroom with double bed in dubai" },
    { src: yacht_44ft_kitchen, alt: "44ft yacht kitchen in dubai" },
    { src: yacht_44ft_dining, alt: "44ft yacht portico dining table in dubai" },
  ];

    return (

<div>

<Helmet>

<title>44Ft Yacht for Rent - Book Online</title>
<meta name="description" content="Discover the best 42ft yacht for rent in Dubai, accommodating up to 12 guests, for AED 41 Dirhams) per person including meals. Book online and get an extra discount"/>
<meta name="keywords" content="book dubai yacht, 44ft yacht dubai, mini yacht dubai, small yacht dubai, dubai marina yacht, yacht rental dubai, dubai yacht ride" />
<link rel="canonical" href="https://dubaiyachtevents.com/yacht44ft"/>
<link rel="icon" title="" href={favicon} type="image/x-icon"></link>
      <script type="application/ld+json">
{JSON.stringify({
  "@context": "https://schema.org",
  "@graph": [
    {
      "@type": "WebPage",
      "@id": "https://dubaiyachtevents.com/yacht44ft",
      "url": "https://dubaiyachtevents.com/yacht44ft",
      "name": "44Ft Yacht for Rent - Book Online",
      "description": "Discover the best 42ft yacht for rent in Dubai, accommodating up to 12 guests, for AED 41 Dirhams) per person including meals. Book online and get an extra discount",
      "publisher": {
        "@type": "Organization",
        "name": "Dubai Yacht Events",
        "logo": {
          "@type": "ImageObject",
          "url": "https://dubaiyachtevents.com/static/media/logo.b4a5c51688c411ee299c.webp"
        },
        "url": "https://dubaiyachtevents.com/",
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+971545619773",
          "contactType": "Customer Service",
          "areaServed": ["EN", "AE", "HI", "ML", "TA"]
        }
      }
    },
    {
      "@type": "WebSite",
      "name": "Dubai Yacht Events",
      "alternateName": "Dubai Yacht Ride",
      "url": "https://dubaiyachtevents.com/",
      "potentialAction": {
        "@type": "SearchAction",
        "target": {
          "@type": "EntryPoint",
          "urlTemplate": "https://query.dubaiyachtevents.com/search?q={search_term_string}"
        },
        "query-input": "required name=search_term_string"
      }
    },
    {
      "@type": "Product",
      "name": "44Ft Dubai Yacht-Dubai Marina Yacht Rental",
      "description": "Discover the best 42ft yacht for rent in Dubai, accommodating up to 12 guests, for AED 41 Dirhams) per person including meals. Book online and get an extra discount",
      "sku": "YACHT006",
      "brand": {
        "@type": "Brand",
        "name": "Dubai Yacht Events"
      },
      "offers": {
        "@type": "Offer",
        "price": "399.00",
        "priceCurrency": "AED",
        "priceValidUntil": "2024-12-31",
        "availability": "https://schema.org/InStock",
        "url": "https://dubaiyachtevents.com/yacht44ft",
        "seller": {
          "@type": "Organization",
          "name": "Dubai Yacht Events"
        }
      },
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.9",
        "reviewCount": "9898"
      },
      "image": [
        "https://dubaiyachtevents.com/static/media/44ft-dubai-yacht-dubai-marina-yacht-rental.0648bfd66a8b90148dcc.webp",
        "https://dubaiyachtevents.com/static/media/44ft-yacht-bedroom.11e7ef787204bca2ee91.webp",
        "https://dubaiyachtevents.com/static/media/44ft-yacht-double-bed.d8f728316513c98bb353.webp",
        "https://dubaiyachtevents.com/static/media/44ft-yacht-kitchen.9d9cfc2b3afecf4cfe5a.webp",
        "https://dubaiyachtevents.com/static/media/44ft-yacht-outer-dining.dd9c97d32f2a7a05589f.webp"
      ]
    },
    {
      "@type": "FAQPage",
      "mainEntity": [
        {
          "@type": "Question",
          "name": "How to rent 44ft yacht in Dubai?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Contact our support (dubaiyachtevents.com) via call or WhatsApp, then confirm the price and book your slot."
          }
        },
        {
          "@type": "Question",
          "name": "Where can I ride this 44 ft yacht in Dubai?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "This 44ft yacht is available in Dubai Marina. You can start your ride from Dubai Marina."
          }
        },
        {
          "@type": "Question",
          "name": "How many people can travel in 44ft yacht in Dubai?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Maximum 12 people can travel per ride in the 44 ft yacht in Dubai."
          }
        },
        {
          "@type": "Question",
          "name": "How to book 44ft yacht in Dubai Marina?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Dubaiyachtevents.com is the best website to book a yacht in Dubai with 0% commission."
          }
        },
        {
          "@type": "Question",
          "name": "What is the price for the 44ft yacht in Dubai?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "The price for the 44ft yacht in Dubai is approximately AED 399."
          }
        }
      ]
    },
    {
      "@type": "Place",
      "name": "Dubai Marina",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Dubai Marina",
        "addressLocality": "Dubai",
        "addressRegion": "Dubai",
        "addressCountry": "AE"
      }
    },
    {
      "@type": "LocalBusiness",
      "name": "Dubai Yacht Events",
      "image": "https://dubaiyachtevents.com/static/media/logo.b4a5c51688c411ee299c.webp",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Dubai Marina",
        "addressLocality": "Dubai",
        "addressRegion": "Dubai",
        "addressCountry": "AE"
      },
      "url": "https://dubaiyachtevents.com/",
      "telephone": "+971545619773",
      "priceRange": "399 AED",
      "sameAs": [
        "https://m.facebook.com/vcyachtsdubai/",
        "https://www.instagram.com/boatrental_dubai?igsh=MTUxYW1oeDJoMXR2Mg==",
        "https://pin.it/2CuYXWb",
        "https://x.com/boatrentdubai?t=SmkLRKWMHCWGQMhQJ55UtQ&s=09",
        "https://www.youtube.com/channel/UC9SsG2PjxUsKrMc9QdhuhRQ",
        "https://www.linkedin.com/in/boat-rental-dubai-6391b5256/",
        "https://boatrentaldubaimarina.blogspot.com/"
      ],
      "openingHoursSpecification": [
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday"
          ],
          "opens": "00:00",
          "closes": "23:59"
        }
      ]
    }
  ]
})}
</script>



<script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Yachts For Rent",
        "item": "https://dubaiyachtevents.com/yacht_rental"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Boats For Rent",
        "item": "https://dubaiyachtevents.com/boat_rental_dubai"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "WaterSports",
        "item": "https://dubaiyachtevents.com/#location"
      },
      {
        "@type": "ListItem",
        "position": 4,
        "name": "Dubai Events",
        "item": "https://dubaiyachtevents.com/"
      }
    ]
  })}
  </script>




     
     </Helmet>
<Header/>

<ScrollArrows/>


    <div className = "main-wrapper">
        <div className = "container">
            <div className = "product-div">
                <div className = "product-div-left">
                <YachtImageShowcase 
                     mainImage={yacht_44ft} 
                     mainAlt="44ft yacht in dubai" 
                     hoverImages={hoverImages} 
                   />
                </div>
                <div className = "product-div-right">
                    <span className = "product-name">44Ft Dubai Yacht-Dubai Marina Yacht Rental</span>
                    <span className = "product-price">AED 499</span>
                    <div className = "product-rating">
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star-half-alt"></i></span>
                        <span>(925 ratings)</span>
                    </div>
                    <p className = "product-description"><ul>
                        <li>1. <b>Crew:</b> Experienced captain and crew on the yacht.They guide you until your ride ends.</li>
                        <li>2. <b>Entertainment:</b> Music system available on the yacht.Let Dance together.</li>
                        <li>3. <b>Safety:</b> Life jackets for all passengers.Enjoy your ride with safety</li>
                        <li>4. <b>Fishing:</b> Provided fishing equipment</li>
                        <li>5. <b>Swimming:</b> Swimming opportunities available</li>
                        <li>6. <b>Starters:</b>  Complimentary water and cool drinks</li>
                        <li>7. <b>Food:</b>  Order your favourite food at extra cost</li>
                    </ul></p>
                    <div className="btn-groups">
                        <Link to="https://wa.me/971545619773?text=Hi%20DubaiYachtEvents,%20I%20am%20interested%20in%20your%2044ft%20Majestic%20Wave%20Yacht.%20Can%20we%20finalize%20our%20deal%3F" className="add-cart-btn"><i className="fas fa-info-circle"></i> Enquiry</Link>
                        <Link to="https://wa.me/971545619773?text=Hi%20DubaiYachtEvents,%20I%20am%20interested%20in%20your%2044ft%20Majestic%20Wave%20Yacht.%20Can%20we%20finalize%20our%20deal%3F" className="buy-now-btn"><i className="fas fa-calendar-alt"></i> Rent Now</Link>
                    </div>
                    <div className = "social-links">
                      <p>Share At: </p>
                      <Link to = "#">
                        <i className = "fab fa-facebook-f"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-twitter"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-instagram"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-whatsapp"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-pinterest"></i>
                      </Link>
                    </div>
                    
                    
                    
                </div>
                <div className="yacht-description" style={{marginLeft: '10%' , marginRight: '10%'}}><h1 >Description</h1><br/>
                <p style={{marginTop: '2%'}}>1. A milk-white yacht with a golden glow inside.This yacht offers a premium look within a budget range. The golden color gives you a premium feel.</p>
                <p style={{marginTop: '2%'}}>2. This yacht comes with a bedrooms with very soft mattress with a very neat and luxurious experience, where you can enjoy time with your partner and family</p>
                <img className="desc-img" src={yacht_44ft_hall} alt="44ft yacht hall in dubai" onclick="openModal('modal1')"/>
              
                  <div id="modal1" className="modal">
                      <div className="modal-content">
                          <span className="close" onclick="closeModal('modal1')">&times;</span>
                          <img src={yacht_44ft_hall} alt="44ft yacht hall in dubai" style={{width: '100%'}}/>
                      </div>
                  </div>
                <p style={{marginTop: '2%'}}>3. It comes with a big dining room  with a stove included. If you are looking for a company project discussions, anniversary party, or birthday party, this dining hall gives the best experience</p>
                <img className="desc-img" src={yacht_44ft_bedroom} alt="44ft yacht bedroom in dubai" onclick="openModal('modal2')"/>
              
                  <div id="modal2" className="modal">
                      <div className="modal-content">
                          <span className="close" onclick="closeModal('modal2')">&times;</span>
                          <img src={yacht_44ft_bedroom} alt="44ft yacht bedroom in dubai" style={{width: '100%'}}/>
                      </div>
                  </div>
                <p style={{marginTop: '2%'}}>4. If you want to have a fully satisfying experience on this yacht, book it for nighttime so you can experience the golden glow.</p>
                <p style={{marginTop: '2%'}}>5. It is fully covered with Air cooler , DJ sound system and high speed wifi,LED lightings ,golden lightings etc.</p>
                <img className="desc-img" src={yacht_44ft_dining} alt="44ft yacht dining table in dubai" onclick="openModal('modal3')"/>
              
                  <div id="modal3" className="modal">
                      <div className="modal-content">
                          <span className="close" onclick="closeModal('modal3')">&times;</span>
                          <img src={yacht_44ft_dining} alt="44ft yacht dining table in dubai" style={{width: '100%'}}/>
                      </div>
                  </div>
                <p style={{marginTop: '2%'}}>6. A super-soft cushion sofa is kept in the dining hall, giving you the best comfort.Another big sofa is kept outside so you can enjoy sunbathing</p>
                <p style={{marginTop: '2%'}}>7. When it comes to safety, we provide life jackets for all passengers. Safety comes first. Enjoy your ride and happy riding!</p>
                <p style={{marginTop: '2%'}}>8. Images provided are only for reference. In reality, it is much bigger than the image; you can't imagine.</p>

            </div>
        </div>
            
        </div>
        
    </div>
    

    <Footer/>


</div>

);
}

export default Yacht44ft;