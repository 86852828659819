import React, { useState, useEffect } from 'react';

const YachtImageShowcase = ({ mainImage, mainAlt, hoverImages }) => {
  const [currentImage, setCurrentImage] = useState({ src: mainImage, alt: mainAlt });

  useEffect(() => {
    if (hoverImages && hoverImages.length > 0) {
      setCurrentImage({ src: hoverImages[0].src, alt: hoverImages[0].alt });
    }
  }, [hoverImages]);

  const handleMouseOver = (image) => {
    setCurrentImage(image);
  };

  return (
    <div className="product-div-left">
      <div className="img-container">
        <img src={currentImage.src} alt={currentImage.alt} />
      </div>
      <div className="hover-container">
        {hoverImages.map((image, index) => (
          <div
            key={index}
            className={currentImage.src === image.src ? 'active' : ''}
            onMouseOver={() => handleMouseOver(image)}
          >
            <img src={image.src} alt={image.alt} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default YachtImageShowcase;
