import React, { useState } from 'react';

const ReservationForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    preferredDate: '',
    guests: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Create the WhatsApp message
    const whatsappMessage = `Hello,\n\nFull Name: ${formData.fullName}\nEmail Address: ${formData.email}\nPhone Number: ${formData.phone}\nPreferred Date: ${formData.preferredDate}\nNumber of Guests: ${formData.guests}`;

    // Encode the message for the URL
    const encodedMessage = encodeURIComponent(whatsappMessage);

    // Specify the recipient phone number (replace with the desired number)
    const recipientNumber = '971545619773'; // UAE number without the '+'

    // Create the WhatsApp URL with the recipient number
    const whatsappURL = `https://wa.me/${recipientNumber}?text=${encodedMessage}`;

    // Redirect to WhatsApp
    window.location.href = whatsappURL;
  };

  return (
    <section className="section hero" id="home" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div className="container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <form onSubmit={handleSubmit} className="booking-form" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h1>Book the Best Yacht in Dubai</h1>
          <div className="form-group">
            <label htmlFor="fullName" className="form-label">Full Name</label>
            <input 
              type="text" 
              name="fullName" 
              id="fullName" 
              className="form-control" 
              placeholder="Full name" 
              value={formData.fullName} 
              onChange={handleChange} 
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email" className="form-label">Email Address</label>
            <input 
              type="email" 
              name="email" 
              id="email" 
              className="form-control" 
              placeholder="Email address" 
              value={formData.email} 
              onChange={handleChange} 
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone" className="form-label">Phone Number</label>
            <input 
              type="tel" 
              name="phone" 
              id="phone" 
              className="form-control" 
              placeholder="Phone number" 
              value={formData.phone} 
              onChange={handleChange} 
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="preferredDate" className="form-label">Preferred Date</label>
            <input 
              type="date" 
              name="preferredDate" 
              id="preferredDate" 
              className="form-control" 
              value={formData.preferredDate} 
              onChange={handleChange} 
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="guests" className="form-label">Number of Guests</label>
            <input 
              type="number" 
              name="guests" 
              id="guests" 
              className="form-control" 
              placeholder="Number of guests" 
              value={formData.guests} 
              onChange={handleChange} 
              required
            />
          </div>
          <button type="submit" className="btn-submit">Make Reservation</button>
        </form>
      </div>
    </section>
  );
};

export default ReservationForm;
