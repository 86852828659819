import React from 'react';
import { Helmet } from 'react-helmet';
import './styles.css';
import favicon from '../assets/images/favicon.webp';
import Header from '../Header';
import about_us from'./images/about-us.webp';
import Footer from '../Footer';




function AboutUs() {
    return (

<div>

    <Helmet>

	<title>Best Yacht & Boat Available in Dubai -Book Online</title>
	<meta name="description" content="Discover the best yachts & boats available in Dubai. Book online now for luxury ride, 24/7 service, and the best prices."/>
    <meta name="keywords" content="about dubai yacht events, book dubai yacht, book dubai boat"/>    
	<link rel="canonical" href="https://dubaiyachtevents.com/about_us"/>
	<link rel="icon" title="" href={favicon} type="image/x-icon"></link>
      <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Yachts For Rent",
          "item": "https://dubaiyachtevents.com/yacht_rental"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Boats For Rent",
          "item": "https://dubaiyachtevents.com/boat_rental_dubai"
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": "WaterSports",
          "item": "https://dubaiyachtevents.com/#location"
        },
        {
          "@type": "ListItem",
          "position": 4,
          "name": "Dubai Events",
          "item": "https://dubaiyachtevents.com/"
        }
      ]
    })}
    </script>
     </Helmet>

    <Header/>
	<div className="about-sec">
		<div className="about-img">
			<img src={about_us} alt=''/>
		</div>
		<div className="about-intro">
			<h3>About Us<span style={{color: 'var(--carolina-blue)'}}> !</span></h3>
			<p>We are the first in Dubai to offer yacht and boat rentals. We have now entered the market with a fresh new website to provide the best user experience. This website is not only for yacht and boat rentals; we also offer fishing trips and watersports events. No other agents provide such low prices. Support us by using our website. If you find any bugs on our site or have any queries, feel free to contact us via WhatsApp, and we will fix them as soon as possible.We have worked very hard to provide a full user experience with a user-friendly interface, allowing you to navigate any page easily. Our website features a simple UI with lots of useful information</p>
		</div>
	</div>

    <Footer/>
	
	  

</div>

    );
}

export default AboutUs;
