import React from 'react';
import { Helmet } from 'react-helmet';
import '../assets/css/yacht_styles.css';
import favicon from '../assets/images/favicon.webp';
import { Link } from 'react-router-dom';
import Header from '../Header';
import ScrollArrows from '../ScrollArrows';
import yacht_premium_luxury_101ft from'../assets/images/yacht-images/101ft-premium-luxury-yacht-dubai-dubai-marina-yacht-rental/101ft-premium-luxury-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_premium_luxury_101ft_hall from'../assets/images/yacht-images/101ft-premium-luxury-yacht-dubai-dubai-marina-yacht-rental/101-ft-silver-wave-yacht-big-hall.webp';
import yacht_premium_luxury_101ft_bedroom from'../assets/images/yacht-images/101ft-premium-luxury-yacht-dubai-dubai-marina-yacht-rental/101-ft-silver-wave-yacht-bed-room.webp';
import yacht_premium_luxury_101ft_side_view from'../assets/images/yacht-images/101ft-premium-luxury-yacht-dubai-dubai-marina-yacht-rental/101-ft-silver-wave-yacht-side-view.webp';
import yacht_premium_luxury_101ft_dining from'../assets/images/yacht-images/101ft-premium-luxury-yacht-dubai-dubai-marina-yacht-rental/101-ft-silver-wave-yacht-dining-room.webp';
import yacht_premium_luxury_101ft_balcony from'../assets/images/yacht-images/101ft-premium-luxury-yacht-dubai-dubai-marina-yacht-rental/101-ft-silver-wave-yacht-portico.webp';
import YachtImageShowcase from './YachtImageShowcase';
import Footer from '../Footer';



function YachtPremiumLuxury101ft() {
    const hoverImages = [
        { src: yacht_premium_luxury_101ft, alt: "101ft luxury yacht in dubai" },
        { src: yacht_premium_luxury_101ft_bedroom, alt: "101ft luxury yacht bedroom in dubai" },
        { src: yacht_premium_luxury_101ft_hall, alt: "101ft luxury yacht big hall in dubai" },
        { src: yacht_premium_luxury_101ft_dining, alt: "101ft luxury yacht dining room in dubai" },
        { src: yacht_premium_luxury_101ft_balcony, alt: "101ft luxury yacht portico in dubai" },
      ];

    return (

<div>

<Helmet>

<title>101Ft Yacht for Rent - Book Online</title>
<meta name="description" content="Discover the best 101ft yacht for rent in Dubai, accommodating up to 65 guests, for AED 38 (Dirhams) per person including meals. Book online and get an extra discount"/>
<meta name="keywords" content="book dubai yacht, 101ft yacht dubai, mini yacht dubai, small yacht dubai, dubai marina yacht, yacht rental dubai, dubai yacht ride" />
<link rel="canonical" href="https://dubaiyachtevents.com/yacht_premium_luxury_101ft"/>
<link rel="icon" title="" href={favicon} type="image/x-icon"></link>
      <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "WebPage",
          "@id": "https://dubaiyachtevents.com/yacht_premium_luxury_101ft",
          "url": "https://dubaiyachtevents.com/yacht_premium_luxury_101ft",
          "name": "101Ft Yacht for Rent - Book Online",
          "description": "Discover the best 101ft yacht for rent in Dubai, accommodating up to 65 guests, for AED 38 (Dirhams) per person including meals. Book online and get an extra discount",
          "publisher": {
            "@type": "Organization",
            "name": "Dubai Yacht Events",
            "logo": {
              "@type": "ImageObject",
              "url": "https://dubaiyachtevents.com/static/media/logo.b4a5c51688c411ee299c.webp"
            },
            "url": "https://dubaiyachtevents.com/",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+971545619773",
              "contactType": "Customer Service",
              "areaServed": ["EN", "AE", "HI", "ML", "TA"]
            }
          }
        },
        {
          "@type": "WebSite",
          "name": "Dubai Yacht Events",
          "alternateName": "Dubai Yacht Ride",
          "url": "https://dubaiyachtevents.com/",
          "potentialAction": {
            "@type": "SearchAction",
            "target": {
              "@type": "EntryPoint",
              "urlTemplate": "https://query.dubaiyachtevents.com/search?q={search_term_string}"
            },
            "query-input": "required name=search_term_string"
          }
        },
        {
          "@type": "Product",
          "name": "101Ft Premium Luxury Yacht Dubai-Dubai Marina Yacht Rental",
          "description": "Discover the best 101ft yacht for rent in Dubai, accommodating up to 65 guests, for AED 38 (Dirhams) per person including meals. Book online and get an extra discount",
          "sku": "YACHT0022",
          "brand": {
            "@type": "Brand",
            "name": "Dubai Yacht Events"
          },
          "offers": {
            "@type": "Offer",
            "price": "2299.00",
            "priceCurrency": "AED",
            "priceValidUntil": "2024-12-31",
            "availability": "https://schema.org/InStock",
            "url": "https://dubaiyachtevents.com/yacht_premium_luxury_101ft",
            "seller": {
              "@type": "Organization",
              "name": "Dubai Yacht Events"
            }
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.9",
            "reviewCount": "59878"
          },
          "image": [
            "https://dubaiyachtevents.com/static/media/101ft-premium-luxury-yacht-dubai-dubai-marina-yacht-rental.602f820b113ff18a9360.webp",
            "https://dubaiyachtevents.com/static/media/101-ft-silver-wave-yacht-bed-room.c89a37592122a08035cc.webp",
            "https://dubaiyachtevents.com/static/media/101-ft-silver-wave-yacht-big-hall.d955ee8e91bf5e761dff.webp",
            "https://dubaiyachtevents.com/static/media/101-ft-silver-wave-yacht-dining-room.b5663c2e7ef9e99254d8.webp",
            "https://dubaiyachtevents.com/static/media/101-ft-silver-wave-yacht-portico.202de092b6ed331b597e.webp"
          ]
        },
        {
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "How to rent 101ft yacht in dubai?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Contact our support(dubaiyachtevents.com) via call or watsapp then Confirm the price and book you slot"
              }
            },
            {
              "@type": "Question",
              "name": "Where i can ride this 101ft yacht in dubai?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "This 101ft yacht is available in dubai marina.You can start your ride from dubai marina."
              }
            },
            {
              "@type": "Question",
              "name": "how many person can travel in 101ft yacht in dubai?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Maximum 80 person can travel per ride in 101 ft yacht in dubai"
              }
            },
            {
              "@type": "Question",
              "name": "How to book 101ft yacht in dubai marina?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "dubaiyachtevents.com is the best website to book yacht in Dubai with 0% commission."
              }
            },
            {
              "@type": "Question",
              "name": "What is the price for the 101ft yacht in dubai?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The price for the 101ft yacht in dubai is approximately AED 2299"
              }
            }
          ]
        },
        {
          "@type": "Place",
          "name": "Dubai Marina",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Dubai Marina",
            "addressLocality": "Dubai",
            "addressRegion": "Dubai",
            "addressCountry": "AE"
          }
        },
        {
          "@type": "LocalBusiness",
          "name": "Dubai Yacht Events",
          "image": "https://dubaiyachtevents.com/static/media/logo.b4a5c51688c411ee299c.webp",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Dubai Marina",
            "addressLocality": "Dubai",
            "addressRegion": "Dubai",
            "addressCountry": "AE"
          },
          "url": "https://dubaiyachtevents.com/",
          "telephone": "+971545619773",
          "priceRange": "2299 AED",
          "sameAs": [
          "https://m.facebook.com/vcyachtsdubai/",
          "https://www.instagram.com/boatrental_dubai?igsh=MTUxYW1oeDJoMXR2Mg==",
          "https://pin.it/2CuYXWb",
          "https://x.com/boatrentdubai?t=SmkLRKWMHCWGQMhQJ55UtQ&s=09",
          "https://www.youtube.com/channel/UC9SsG2PjxUsKrMc9QdhuhRQ",
          "https://www.linkedin.com/in/boat-rental-dubai-6391b5256/",
          "https://boatrentaldubaimarina.blogspot.com/"
          ],
          "openingHoursSpecification": [
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday"
              ],
              "opens": "00:00",
              "closes": "23:59"
            }
          ]
        }
      ]
    })}
    </script>
<script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 3,
        "name": "Yachts For Rent",
        "item": "https://dubaiyachtevents.com/yacht_rental"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Boats For Rent",
        "item": "https://dubaiyachtevents.com/boat_rental_dubai"
      },
      {
        "@type": "ListItem",
        "position": 4,
        "name": "WaterSports",
        "item": "https://dubaiyachtevents.com/#location"
      },
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Dubai Events",
        "item": "https://dubaiyachtevents.com/"
      }
    ]
  })}
  </script>



     
     </Helmet>


<Header/>

<ScrollArrows/>


    <div className = "main-wrapper">
        <div className = "container">
            <div className = "product-div">
                <div className = "product-div-left">
                <YachtImageShowcase 
                     mainImage={yacht_premium_luxury_101ft} 
                     mainAlt="101ft luxury yacht in dubai" 
                     hoverImages={hoverImages} 
                   />
                </div>
                <div className = "product-div-right">
                    <span className = "product-name">101Ft Premium Luxury Yacht Dubai-Dubai Marina Yacht Rental</span>
                    <span className = "product-price">AED 2499</span>
                    <div className = "product-rating">
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star-half-alt"></i></span>
                        <span>(5111 ratings)</span>
                    </div>
                    <p className = "product-description"><ul>
                        
                      <li>1. <b>Crew:</b> Experienced captain and crew are available to support you throughout the entire ride</li>
                        <li>2. <b>Entertainment:</b> High Quality Dj sound system available</li>
                        <li>3. <b>Safety:</b> Life jackets for all passengers</li>
                        <li>4. <b>Fishing:</b> fishing equipment Provided.Lets make some fun with friends </li>
                        <li>5. <b>Swimming:</b> Swimming opportunities available</li>
                        <li>6. <b>Starters:</b>  water and soft drinks will be provided for all passengers</li>
                        <li>6. <b>Top features:</b>   Luxury Bathtub will be provided</li>

                        
                        
                    </ul></p>
                    <div className="btn-groups">
                        <Link to="https://wa.me/971545619773?text=Hi%20DubaiYachtEvents,%20I%20am%20interested%20in%20your%20101ft%20Silver%20Wave%20Yacht.%20Can%20we%20finalize%20our%20deal%3F" className="add-cart-btn"><i className="fas fa-info-circle"></i> Enquiry</Link>
                        <Link to="https://wa.me/971545619773?text=Hi%20DubaiYachtEvents,%20I%20am%20interested%20in%20your%20101ft%20Silver%20Wave%20Yacht.%20Can%20we%20finalize%20our%20deal%3F" className="buy-now-btn"><i className="fas fa-calendar-alt"></i> Rent Now</Link>
                    </div>
                    <div className = "social-links">
                      <p>Share At: </p>
                      <Link to = "#">
                        <i className = "fab fa-facebook-f"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-twitter"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-instagram"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-whatsapp"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-pinterest"></i>
                      </Link>
                    </div>
                    
                    
                    
                </div>
                <div style={{marginLeft: '10%' , marginRight: '10%'}}><h1 >Description</h1><br/>
                  <p style={{marginTop: '2%'}}>1. Total 65 person can travel per ride</p>
                  <p style={{marginTop: '2%'}}>2.  This is the VIP premium luxury yacht. A maximum of 80 people can enjoy this ride.</p>
                  <img className="desc-img" src={yacht_premium_luxury_101ft_side_view} alt="101ft luxury yacht side view in dubai" onclick="openModal('modal1')"/>
              
                  <div id="modal1" className="modal">
                      <div className="modal-content">
                          <span className="close" onclick="closeModal('modal1')">&times;</span>
                          <img src={yacht_premium_luxury_101ft_side_view} alt="101ft luxury yacht side view in dubai" style={{width: '100%'}}/>
                      </div>
                  </div>
                  <p style={{marginTop: '2%'}}>3. Don't waste your money booking a room; book a yacht instead. It's better than luxury rooms.</p>
                  <p style={{marginTop: '2%'}}>4. A big hall with meals, along with cool drinks, will make you feel like you're in heaven. A big 100-inch TV in the hall gives a theatrical experience.</p>
                  <img className="desc-img" src={yacht_premium_luxury_101ft_hall} alt="101ft luxury yacht big hall in dubai" onclick="openModal('modal2')"/>
              
                  <div id="modal2" className="modal">
                      <div className="modal-content">
                          <span className="close" onclick="closeModal('modal2')">&times;</span>
                          <img src={yacht_premium_luxury_101ft_hall} alt="101ft luxury yacht big hall in dubai" style={{width: '100%'}}/>
                      </div>
                  </div>
                  <p style={{marginTop: '2%'}}>5. A special bed is placed in the open area of the yacht, giving you a luxurious experience.</p>
                  <p style={{marginTop: '2%'}}>6. We provide free cancellation only for customers who book online</p>
                  <p style={{marginTop: '2%'}}>7. Images provided are only for reference. In reality, it is much bigger than the image; you can't imagine.</p>
                  <img className="desc-img" src={yacht_premium_luxury_101ft_dining} alt="101ft luxury yacht dining room in dubai" onclick="openModal('modal3')"/>
              
                  <div id="modal3" className="modal">
                      <div className="modal-content">
                          <span className="close" onclick="closeModal('modal3')">&times;</span>
                          <img src={yacht_premium_luxury_101ft_dining} alt="101ft luxury yacht dining room in dubai" style={{width: '100%'}}/>
                      </div>
                  </div>
                  <p style={{marginTop: '2%'}}>8. All taxes are included in the price, so there are no additional costs.Dont worry about additional cost</p>
                  <p style={{marginTop: '2%'}}>9. In addition, you can enjoy water sports such as donut rides, banana rides, jetski and more</p>
                  <p style={{marginTop: '2%'}}>10. All these luxury experiences at a low price. Book now!</p>




            </div>
        </div>
            
        </div>
        
    </div>
    

    <Footer/>


</div>

);
}

export default YachtPremiumLuxury101ft;