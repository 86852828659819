import React, { useState } from 'react';

const HeroForm = () => {
  // State to store form data
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    preferredDate: '',
    guests: '',
  });

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const { fullName, email, phone, preferredDate, guests } = formData;

    const message = `New Reservation Request:\n\nFull Name: ${fullName}\nEmail: ${email}\nPhone: ${phone}\nPreferred Date: ${preferredDate}\nNumber of Guests: ${guests}`;

    const whatsappLink = `https://wa.me/971545619773/?text=${encodeURIComponent(message)}`;
    window.location.href = whatsappLink;
  };

  return (
    <section className="section hero" id="home">
      <div className="container">
        <div className="hero-content">
          <h2 className="h1 hero-title">The easy way to book a <strong>yacht ride</strong> in Dubai</h2>
          <p className="hero-text">
            Looking For <strong>Luxury Yachts Dubai</strong>? Then book your slot soon!
          </p>
        </div>
        <div className="hero-banner"></div>
        <form id="reservationForm" className="booking-form" onSubmit={handleSubmit}>
          <h1>Book your Yacht in Dubai Marina</h1>
          <div className="form-group">
            <label htmlFor="full-name" className="form-label">Full Name</label>
            <input
              type="text"
              name="fullName"
              id="full-name"
              className="form-control"
              placeholder="Enter your full name"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email" className="form-label">Email Address</label>
            <input
              type="email"
              name="email"
              id="email"
              className="form-control"
              placeholder="Enter your email address"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone" className="form-label">Phone Number</label>
            <input
              type="tel"
              name="phone"
              id="phone"
              className="form-control"
              placeholder="Enter your phone number"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="preferred-date" className="form-label">Preferred Date</label>
            <input
              type="date"
              name="preferredDate"
              id="preferred-date"
              className="form-control"
              value={formData.preferredDate}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="guests" className="form-label">Number of Guests</label>
            <input
              type="number"
              name="guests"
              id="guests"
              className="form-control"
              placeholder="Enter the number of guests"
              value={formData.guests}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="btn-submit">Make Reservation</button>
        </form>
      </div>
    </section>
  );
};

export default HeroForm;
