import React from 'react';

const ScrollArrows = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };

  return (
    <div className="scroll-arrows">
      <div className="scroll-arrow" onClick={scrollToTop}>&#8593;</div>
      <div className="scroll-arrow" onClick={scrollToBottom}>&#8595;</div>
    </div>
  );
};

export default ScrollArrows;
