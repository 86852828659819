import React from 'react';
import { Helmet } from 'react-helmet';
import '../assets/css/style.css';
import favicon from '../assets/images/favicon.webp';
import Header from '../Header';
import { Link } from 'react-router-dom';
import Footer from '../Footer';


function BoatRidePrice() {
  return (
    
    
    <div >

    <Helmet>

    <title>Boat Ride Dubai Price List - Best Price Deals</title>
    <meta name="description" content="Check the boat ride Dubai price list for the best price deals. Book now to enjoy affordable luxury boat rides, 24/7 service, and special offers."/>
    <meta name="keywords" content="dubai boat events prices, dubai boat rental prices, boat rental dubai prices, boat prices in dubai, boat ride prices dubai, dubai boat price, dubai boat ride price" />
    <link rel="canonical" href="https://dubaiyachtevents.com/boat_ride_price"/>
    <link rel="icon" title="" href={favicon} type="image/x-icon"></link>
      <script type="application/ld+json">
     {JSON.stringify({
       "@context": "https://schema.org",
       "@type": "BreadcrumbList",
       "itemListElement": [
         {
           "@type": "ListItem",
           "position": 1,
           "name": "Yachts For Rent",
           "item": "https://dubaiyachtevents.com/yacht_rental"
         },
         {
           "@type": "ListItem",
           "position": 2,
           "name": "Boats For Rent",
           "item": "https://dubaiyachtevents.com/boat_rental_dubai"
         },
         {
           "@type": "ListItem",
           "position": 3,
           "name": "WaterSports",
           "item": "https://dubaiyachtevents.com/#location"
         },
         {
           "@type": "ListItem",
           "position": 4,
           "name": "Dubai Events",
           "item": "https://dubaiyachtevents.com/"
         }
       ]
     })}
     </script>

     
     </Helmet>
   

 <Header/>

  <section class="pricing-table" style={{marginTop: '7%'}}>
    <h1><strong>Boat ride dubai price</strong></h1>
    <div class="table-container">
        <table class="styled-table">
            <thead>
                <tr>
                    <th><h1>Boats for Sale</h1></th>
                    <th><h1>Boat Ride Price</h1></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><Link to="/boat_33ft"><strong>33Ft Boat Dubai</strong></Link></td>
                    <td><Link to="/boat_33ft"><strong>379 AED(Dirhams)/10 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/boat_36ft"><strong>36Ft Boat Dubai</strong></Link></td>
                    <td><Link to="/boat_36ft"><strong>399 AED(Dirhams)/10 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/boat_40ft"><strong>40Ft Boat Dubai</strong></Link></td>
                    <td><Link to="/boat_40ft"><strong>449 AED(Dirhams)/10 Guest</strong></Link></td>
                </tr>
                <tr>
                    <td><Link to="/speed_boat"><strong>Speed Boat Dubai</strong></Link></td>
                    <td><Link to="/speed_boat"><strong>399 AED(Dirhams)/10 Guest</strong></Link></td>
                </tr>               
            </tbody>
        </table>
    </div>
</section>



  <Footer/>

  
 
</div>

 );
}

export default BoatRidePrice;