import React from 'react';
import { Helmet } from 'react-helmet';
import '../assets/css/style.css';
import favicon from '../assets/images/favicon.webp';
import Header from '../Header';
import HeroForm from '../HeroForm';
import { Link } from 'react-router-dom';
import ScrollArrows from '../ScrollArrows';
import yacht_42ft from'../assets/images/dubai-yacht-images/42ft-dubai-yacht-dubai-marina-yacht-rental.webp';
import yacht_44ft from'../assets/images/dubai-yacht-images/44ft-dubai-yacht-dubai-marina-yacht-rental.webp';
import yacht_45ft from'../assets/images/dubai-yacht-images/45ft-dubai-yacht-dubai-marina-yacht-rental.webp';
import yacht_55ft from'../assets/images/dubai-yacht-images/55ft-azimut-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_55_1ft from'../assets/images/dubai-yacht-images/55ft-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_56ft from'../assets/images/dubai-yacht-images/56ft-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_65ft from'../assets/images/dubai-yacht-images/65ft-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_68ft from'../assets/images/dubai-yacht-images/68ft-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_75ft from'../assets/images/dubai-yacht-images/75ft-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_80ft from'../assets/images/dubai-yacht-images/80ft-yacht-dubai-dubai-marina-yacht-rental.webp';
import luxury_yacht_80ft from'../assets/images/dubai-yacht-images/80ft-luxury-yacht-dubai-dubai-marina-yacht-rental.webp';
import luxury_yacht_88ft from'../assets/images/dubai-yacht-images/88ft-luxury-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_90ft from'../assets/images/dubai-yacht-images/90ft-yacht-dubai-dubai-marina-yacht-rental.webp';
import luxury_yacht_90ft from'../assets/images/dubai-yacht-images/90ft-luxury-yacht-dubai-dubai-marina-yacht-rental.webp';
import vip_luxury_yacht_90ft from'../assets/images/dubai-yacht-images/90ft-luxury-vip-yacht-dubai-dubai-marina-yacht-rental.webp';
import luxury_yacht_95ft from'../assets/images/dubai-yacht-images/95ft-luxury-yacht-dubai-dubai-marina-yacht-rental.webp';
import family_yacht_100ft from'../assets/images/dubai-yacht-images/100ft-family-yacht-dubai-dubai-marina-yacht-rental.webp';
import premium_luxury_yacht_101ft from'../assets/images/dubai-yacht-images/101ft-premium-luxury-yacht-dubai-dubai-marina-yacht-rental.webp';
import Footer from '../Footer';


function YachtRental() {
  return (


  <div>
<Helmet>

<title>Yachts in Dubai - Best Price Deal</title>
<meta name="description" content="Find the best price deals on yachts in Dubai. Book now to enjoy luxury yachts, 24/7 service, and exclusive offers."/>
<link rel="canonical" title="" href="https://dubaiyachtevents.com/yacht_rental"/>
<meta name="keywords" content="Dubai yacht rental, luxury yacht Dubai, rent yacht Dubai, yacht party Dubai, book dubai yacht, book dubai boat, boat rental dubai, dubai boat ride, dubai marina boat, watersports dubai, dubai waterfun, dubai parasailing, water events dubai, dubai yacht events, yacht rental dubai, boat charter dubai, boat cruises dubai, luxury cruise dubai, yacht rental in dubai, boat trip dubai, sea trip dubai, boat hire dubai, boat hire in dubai, fishing trip dubai, boat rent dubai, boat tour in dubai, yacht charter dubai, dubai sea cruise, private charter dubai, dubai yacht charter, dubai yacht rental, fishing trips dubai, rent boat dubai, boat tour dubai, rent a boat dubai, rent a yacht dubai, sea tour dubai, dubai boat rental, dubai boat rentals, boat rental in dubai, boat hire in dubai, boat rental dubai, boat hire dubai, yacht rental in duabi, boat rentals dubai, yacht charters dubai, boat charter dubai, yacht charters in dubai, rent boat in dubai, rent a boat dubai, rent boat dubai, sea tour dubai, sea trip dubai, yacht charter dubai, yacht charter in dubai, dubai yacht cruise, dubai boat charter, boat hire dubai"/>
<link rel="icon" title="" href={favicon} type="image/x-icon"></link>
      <script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Yachts For Rent",
        "item": "https://dubaiyachtevents.com/yacht_rental"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Boats For Rent",
        "item": "https://dubaiyachtevents.com/boat_rental_dubai"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "WaterSports",
        "item": "https://dubaiyachtevents.com/#location"
      },
      {
        "@type": "ListItem",
        "position": 4,
        "name": "Dubai Events",
        "item": "https://dubaiyachtevents.com/"
      }
    ]
  })}
  </script>



     
     </Helmet>

  <Header/>



  <ScrollArrows />

  <main>
    <article>

     

    <HeroForm />
      






      <section class="section super-yacht" id="super-yacht">
        <div class="container">

          <div class="title-wrapper">
            <h1 class="h2 section-title"><strong>Yacht Rental Dubai</strong></h1>

          </div>

          <p> You all know that living in Dubai is costly, but a <strong>yacht rental </strong>in Dubai is not as expensive. All people who love <strong>yacht rentals</strong> can enjoy this experience. Confirm your budget and choose a yacht within your price range. Options include <strong>private yachts</strong>, <strong>shared tours</strong>, or <strong>luxury yachts</strong>.A <strong>Dubai yacht ride</strong> offers the opportunity to explore the city's important landmarks. Popular sights include <strong>Dubai Marina</strong>, <strong>Palm Jumeirah</strong>, <strong>Burj Al Arab</strong>, and <strong>Atlantis,The Palm</strong>. Additionally, you can enjoy activities like sunbathing, swimming, underwater diving, <strong>fishing</strong>, and <strong>water sports</strong>, with dining and entertainment options on board.The best time to experience a yacht ride is from October to April. Sunset cruises are particularly beautiful. Book in advance for a memorable experience.Enjoy the <strong>dubai yacht party</strong> .</p>
          <br/>
          <ul class="super-yacht-list">

            <li>
              <div class="super-yacht-card">

                <figure class="card-banner">
                  <img src={yacht_42ft} title="42ft private yacht" alt="42ft private yacht" loading="lazy" width="440" height="300" class="w-100" onclick="showPopup('popup5')"/>
              </figure>
              <div id="popup5" class="popup">
                  <span class="close" onclick="closePopup('popup5')">&times;</span>
                  <img src={yacht_42ft} title="42ft private yacht" alt="42ft private yacht" loading="lazy" class="popup-image"/>
              </div>

                <div class="card-content">

                  <div class="card-title-wrapper">
                    <h2 class="h3 card-title">
                      <Link title="Redirect to 42Ft Yacht Dubai page" to="/yacht42ft"><strong>42Ft Yacht Dubai</strong></Link>
                    </h2>

                    <data class="place" value="Dubai">Dubai</data>
                  </div>
                  <ul class="card-list">

                    <li class="card-list-item">
                      <ion-icon name="people-outline"></ion-icon>

                      <span class="card-item-text">12 People</span>
                    </li>

                    <li class="card-list-item">
                      <ion-icon name="add-outline"></ion-icon>
                      <span class="card-item-text">AddOn Food Items</span>
                    </li>
                    
                    

                    <li class="card-list-item">
                      <ion-icon name="beer-outline"></ion-icon>
                      <span class="card-item-text">Drinks and Snacks</span>
                    </li>
                    
                    
                    

                    <li class="card-list-item">
                      <ion-icon name="close-outline"></ion-icon>
                      <span class="card-item-text">Free Cancellation</span>
                    </li>

                  </ul>

                  <div class="card-price-wrapper">

                    <p class="card-price">
                      <strong>AED 449</strong> / hour
                    </p>

                    <button class="btn fav-btn" aria-label="Add to favourite list">
                      <ion-icon name="heart-outline"></ion-icon>
                    </button>

                    <Link class="btn" title="Redirect to 42Ft Yacht Dubai page" to="/yacht42ft">Book Now</Link>

                  </div>

                </div>

              </div>
            </li>

            <li>
              <div class="super-yacht-card">

                <figure class="card-banner">
                  <img src={yacht_44ft} title="44ft private yacht" alt="44ft private yacht" loading="lazy" width="440" height="300" class="w-100" onclick="showPopup('popup6')"/>
              </figure>
              <div id="popup6" class="popup">
                  <span class="close" onclick="closePopup('popup6')">&times;</span>
                  <img src={yacht_44ft} title="44ft private yacht" alt="44ft private yacht" loading="lazy" class="popup-image"/>
              </div>

                <div class="card-content">

                  <div class="card-title-wrapper">
                    <h2 class="h3 card-title">
                      <Link title="Redirect to 44Ft Yacht Dubai page" to="/yacht44ft"><strong>44Ft Yacht Dubai</strong></Link>
                    </h2>

                    <data class="place" value="Dubai">Dubai</data>
                  </div>

                  <ul class="card-list">

                    <li class="card-list-item">
                      <ion-icon name="people-outline"></ion-icon>

                      <span class="card-item-text">12 People</span>
                    </li>

                    <li class="card-list-item">
                      <ion-icon name="add-outline"></ion-icon>
                      <span class="card-item-text">AddOn Food Items</span>
                    </li>
                    

                    <li class="card-list-item">
                      <ion-icon name="beer-outline"></ion-icon>
                      <span class="card-item-text">Drinks and Snacks</span>
                    </li>
                    
                    
                    

                    <li class="card-list-item">
                      <ion-icon name="close-outline"></ion-icon>
                      <span class="card-item-text">Free Cancellation</span>
                    </li>

                  </ul>

                  <div class="card-price-wrapper">

                    <p class="card-price">
                      <strong>AED 499</strong> / hour
                    </p>

                    <button class="btn fav-btn" aria-label="Add to favourite list">
                      <ion-icon name="heart-outline"></ion-icon>
                    </button>

                    <Link class="btn" title="Redirect to 44Ft Yacht Dubai page" to="/yacht44ft">Book Now</Link>

                  </div>

                </div>

              </div>
              </li>
              <li>
                <div class="super-yacht-card">
  
                  <figure class="card-banner">
                    <img src={yacht_45ft} title="45ft private yacht" alt="45ft private yacht" loading="lazy" width="440" height="300" class="w-100" onclick="showPopup('popup7')"/>
                </figure>
                <div id="popup7" class="popup">
                    <span class="close" onclick="closePopup('popup7')">&times;</span>
                    <img src={yacht_45ft} title="45ft private yacht" alt="45ft private yacht" loading="lazy" class="popup-image"/>
                </div>
  
                  <div class="card-content">
  
                    <div class="card-title-wrapper">
                      <h2 class="h3 card-title">
                        <Link title="Redirect to 45Ft Yacht Dubai page" to="/yacht45ft"><strong>45Ft Yacht Dubai</strong></Link>
                      </h2>
  
                      <data class="place" value="Dubai">Dubai</data>
                    </div>
  
                    <ul class="card-list">
  
                      <li class="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span class="card-item-text">12 People</span>
                      </li>
  
                      <li class="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span class="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li class="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span class="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li class="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span class="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div class="card-price-wrapper">
  
                      <p class="card-price">
                        <strong>AED 499</strong> / hour
                      </p>
  
                      <button class="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link class="btn" title="Redirect to 45Ft Yacht Dubai page" to="/yacht45ft">Book Now</Link>
  
                    </div>
  
                  </div>
  
                </div>
              </li>
              <li>
                <div class="super-yacht-card">
  
                  <figure class="card-banner">
                    <img src={yacht_55ft} title="55ft azimut yacht" alt="55ft azimut yacht" loading="lazy" width="440" height="300" class="w-100" onclick="showPopup('popup8')"/>
                </figure>
                <div id="popup8" class="popup">
                    <span class="close" onclick="closePopup('popup8')">&times;</span>
                    <img src={yacht_55ft} title="55ft azimut yacht" alt="55ft azimut yacht" loading="lazy" class="popup-image"/>
                </div>
  
                  <div class="card-content">
  
                    <div class="card-title-wrapper">
                      <h2 class="h3 card-title">
                        <Link title="Redirect to 55Ft Azimut Yacht Dubai page" to="/yacht_azimut_55ft"><strong>55Ft Azimut Yacht Dubai</strong></Link>
                      </h2>
  
                      <data class="place" value="Dubai">Dubai</data>
                    </div>
  
                    <ul class="card-list">
  
                      <li class="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span class="card-item-text">14 People</span>
                      </li>
  
                      <li class="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span class="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li class="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span class="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li class="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span class="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div class="card-price-wrapper">
  
                      <p class="card-price">
                        <strong>AED 549</strong> / hour
                      </p>
  
                      <button class="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link class="btn" title="Redirect to 55Ft Azimut Yacht Dubai page" to="/yacht_azimut_55ft">Book Now</Link>
  
                    </div>
  
                  </div>
  
                </div>
              </li>
              <li>
                <div class="super-yacht-card">
  
                  <figure class="card-banner">
                    <img src={yacht_55_1ft} title="55ft luxury yacht" alt="55ft luxury yacht" loading="lazy" width="440" height="300" class="w-100" onclick="showPopup('popup9')"/>
                </figure>
                <div id="popup9" class="popup">
                    <span class="close" onclick="closePopup('popup9')">&times;</span>
                    <img src={yacht_55_1ft} title="55ft luxury yacht" alt="55ft luxury yacht" loading="lazy" class="popup-image"/>
                </div>
  
                  <div class="card-content">
  
                    <div class="card-title-wrapper">
                      <h2 class="h3 card-title">
                        <Link title="Redirect to 55Ft Yacht Dubai page" to="/yacht_luxury_55ft"><strong>55Ft Yacht Dubai</strong></Link>
                      </h2>
  
                      <data class="place" value="Dubai">Dubai</data>
                    </div>
  
                    <ul class="card-list">
  
                      <li class="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span class="card-item-text">22 People</span>
                      </li>
  
                      <li class="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span class="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li class="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span class="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li class="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span class="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div class="card-price-wrapper">
  
                      <p class="card-price">
                        <strong>AED 599</strong> / hour
                      </p>
  
                      <button class="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link class="btn" title="Redirect to 55Ft Yacht Dubai page" to="/yacht_luxury_55ft">Book Now</Link>
  
                    </div>
  
                  </div>
  
                </div>
              </li>
              <li>
                <div class="super-yacht-card">
  
                  <figure class="card-banner">
                    <img src={yacht_56ft} title="56ft luxury yacht" alt="56ft luxury yacht" loading="lazy" width="440" height="300" class="w-100" onclick="showPopup('popup10')"/>
                </figure>
                <div id="popup10" class="popup">
                    <span class="close" onclick="closePopup('popup10')">&times;</span>
                    <img src={yacht_56ft} title="56ft luxury yacht" alt="56ft luxury yacht" loading="lazy" class="popup-image"/>
                </div>
  
                  <div class="card-content">
  
                    <div class="card-title-wrapper">
                      <h2 class="h3 card-title">
                        <Link title="Redirect to 56Ft Yacht Dubai page" to="/yacht_luxury_56ft"><strong>56Ft Yacht Dubai</strong></Link>
                      </h2>
  
                      <data class="place" value="Dubai">Dubai</data>
                    </div>
  
                    <ul class="card-list">
  
                      <li class="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span class="card-item-text">20 People</span>
                      </li>
  
                      <li class="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span class="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li class="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span class="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li class="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span class="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div class="card-price-wrapper">
  
                      <p class="card-price">
                        <strong>AED 699</strong> / hour
                      </p>
  
                      <button class="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link class="btn" title="Redirect to 56Ft Yacht Dubai page" to="/yacht_luxury_56ft">Book Now</Link>
  
                    </div>
  
                  </div>

                  
  
                </div>
              </li>

              <li>
                <div class="super-yacht-card">
  
                  <figure class="card-banner">
                    <img src={yacht_65ft} title="65ft luxury yacht" alt="65ft luxury yacht" loading="lazy" width="440" height="300" class="w-100" onclick="showPopup('popup11')"/>
                </figure>
                <div id="popup11" class="popup">
                    <span class="close" onclick="closePopup('popup11')">&times;</span>
                    <img src={yacht_65ft} title="65ft luxury yacht" alt="65ft luxury yacht" loading="lazy" class="popup-image"/>
                </div>
  
                  <div class="card-content">
  
                    <div class="card-title-wrapper">
                      <h2 class="h3 card-title">
                        <Link title="Redirect to 65Ft Yacht Dubai page" to="/yacht_luxury_65ft"><strong>65Ft Yacht Dubai</strong></Link>
                      </h2>
  
                      <data class="place" value="Dubai">Dubai</data>
                    </div>
  
                    <ul class="card-list">
  
                      <li class="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span class="card-item-text">25 People</span>
                      </li>
  
                      <li class="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span class="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li class="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span class="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li class="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span class="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div class="card-price-wrapper">
  
                      <p class="card-price">
                        <strong>AED 749</strong> / hour
                      </p>
  
                      <button class="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link class="btn" title="Redirect to 65Ft Yacht Dubai page" to="/yacht_luxury_65ft">Book Now</Link>
  
                    </div>
  
                  </div>

                  
  
                </div>
              </li>

              <li>
                <div class="super-yacht-card">
  
                  <figure class="card-banner">
                    <img src={yacht_68ft} title="68ft luxury yacht" alt="68ft luxury yacht" loading="lazy" width="440" height="300" class="w-100" onclick="showPopup('popup12')"/>
                </figure>
                <div id="popup12" class="popup">
                    <span class="close" onclick="closePopup('popup12')">&times;</span>
                    <img src={yacht_68ft} title="68ft luxury yacht" alt="68ft luxury yacht" loading="lazy" class="popup-image"/>
                </div>
  
                  <div class="card-content">
  
                    <div class="card-title-wrapper">
                      <h2 class="h3 card-title">
                        <Link title="Redirect to 68Ft Yacht Dubai page" to="/yacht_luxury_68ft"><strong>68Ft Yacht Dubai</strong></Link>
                      </h2>
  
                      <data class="place" value="Dubai">Dubai</data>
                    </div>
  
                    <ul class="card-list">
  
                      <li class="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span class="card-item-text">25 People</span>
                      </li>
  
                      <li class="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span class="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li class="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span class="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li class="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span class="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div class="card-price-wrapper">
  
                      <p class="card-price">
                        <strong>AED 799</strong> / hour
                      </p>
  
                      <button class="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link class="btn" title="Redirect to 68Ft Yacht Dubai page" to="/yacht_luxury_68ft">Book Now</Link>
  
                    </div>
  
                  </div>

                  
  
                </div>
              </li>

              <li>
                <div class="super-yacht-card">
  
                  <figure class="card-banner">
                    <img src={yacht_75ft} title="75ft luxury yacht" alt="75ft luxury yacht" loading="lazy" width="440" height="300" class="w-100" onclick="showPopup('popup13')"/>
                </figure>
                <div id="popup13" class="popup">
                    <span class="close" onclick="closePopup('popup13')">&times;</span>
                    <img src={yacht_75ft} title="75ft luxury yacht" alt="75ft luxury yacht" loading="lazy" class="popup-image"/>
                </div>
  
                  <div class="card-content">
  
                    <div class="card-title-wrapper">
                      <h2 class="h3 card-title">
                        <Link title="Redirect to 75Ft Yacht Dubai page" to="/yacht_luxury_75ft"><strong>75Ft Yacht Dubai</strong></Link>
                      </h2>
  
                      <data class="place" value="Dubai">Dubai</data>
                    </div>
  
                    <ul class="card-list">
  
                      <li class="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span class="card-item-text">30 People</span>
                      </li>
  
                      <li class="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span class="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li class="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span class="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li class="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span class="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div class="card-price-wrapper">
  
                      <p class="card-price">
                        <strong>AED 949</strong> / hour
                      </p>
  
                      <button class="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link class="btn" title="Redirect to 75Ft Yacht Dubai page" to="/yacht_luxury_75ft">Book Now</Link>
  
                    </div>
  
                  </div>

                  
  
                </div>
              </li>

              <li>
                <div class="super-yacht-card">
  
                  <figure class="card-banner">
                    <img src={yacht_80ft} title="80ft luxury yacht in Dubai on a blue sea" alt="80ft luxury yacht in Dubai on a blue sea" loading="lazy" width="440" height="300" class="w-100" onclick="showPopup('popup16')"/>
                </figure>
                <div id="popup16" class="popup">
                    <span class="close" onclick="closePopup('popup16')">&times;</span>
                    <img src={yacht_80ft} title="80ft luxury yacht in Dubai on a blue sea" alt="80ft luxury yacht in Dubai on a blue sea" loading="lazy" class="popup-image"/>
                </div>
  
                  <div class="card-content">
  
                    <div class="card-title-wrapper">
                      <h2 class="h3 card-title">
                        <Link title="Redirect to 80Ft Yacht Dubai page" to="/yacht_80ft"><strong>80Ft Yacht Dubai</strong></Link>
                      </h2>
  
                      <data class="place" value="Dubai">Dubai</data>
                    </div>
  
                    <ul class="card-list">
  
                      <li class="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span class="card-item-text">35 People</span>
                      </li>
  
                      <li class="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span class="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li class="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span class="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li class="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span class="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div class="card-price-wrapper">
  
                      <p class="card-price">
                        <strong>AED 1199</strong> / hour
                      </p>
  
                      <button class="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link class="btn" title="Redirect to 80Ft Yacht Dubai page" to="/yacht_80ft">Book Now</Link>
  
                    </div>
  
                  </div>

                  
  
                </div>
              </li>

              <li>
                <div class="super-yacht-card">
  
                  <figure class="card-banner">
                    <img src={luxury_yacht_80ft} title="80ft dream luxury yacht" alt="80ft dream luxury yacht" loading="lazy" width="440" height="300" class="w-100" onclick="showPopup('popup15')"/>
                </figure>
                <div id="popup15" class="popup">
                    <span class="close" onclick="closePopup('popup15')">&times;</span>
                    <img src={luxury_yacht_80ft} title="80ft dream luxury yacht" alt="80ft dream luxury yacht" loading="lazy" class="popup-image"/>
                </div>
  
                  <div class="card-content">
  
                    <div class="card-title-wrapper">
                      <h2 class="h3 card-title">
                        <Link title="Redirect to 80Ft Luxury Yacht Dubai page" to="/yacht_luxury_80ft"><strong>80Ft Luxury Yacht Dubai</strong></Link>
                      </h2>
  
                      <data class="place" value="Dubai">Dubai</data>
                    </div>
  
                    <ul class="card-list">
  
                      <li class="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span class="card-item-text">35 People</span>
                      </li>
  
                      <li class="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span class="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li class="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span class="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li class="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span class="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div class="card-price-wrapper">
  
                      <p class="card-price">
                        <strong>AED 1199</strong> / hour
                      </p>
  
                      <button class="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link class="btn" title="Redirect to 80Ft Luxury Yacht Dubai page" to="/yacht_luxury_80ft">Book Now</Link>
  
                    </div>
  
                  </div>

                  
  
                </div>
              </li>

              <li>
                <div class="super-yacht-card">
  
                  <figure class="card-banner">
                    <img src={luxury_yacht_88ft} title="88ft luxury yacht" alt="88ft luxury yacht" loading="lazy" width="440" height="300" class="w-100" onclick="showPopup('popup50')"/>
                </figure>
                <div id="popup50" class="popup">
                    <span class="close" onclick="closePopup('popup50')">&times;</span>
                    <img src={luxury_yacht_88ft} title="88ft luxury yacht" alt="88ft luxury yacht" loading="lazy" class="popup-image"/>
                </div>
  
                  <div class="card-content">
  
                    <div class="card-title-wrapper">
                      <h2 class="h3 card-title">
                        <Link title="Redirect to 88Ft Luxury Yacht Dubai page" to="/yacht_luxury_88ft"><strong>88Ft Luxury Yacht Dubai</strong></Link>
                      </h2>
  
                      <data class="place" value="Dubai">Dubai</data>
                    </div>
  
                    <ul class="card-list">
  
                      <li class="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span class="card-item-text">50 People</span>
                      </li>
  
                      <li class="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span class="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li class="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span class="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li class="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span class="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div class="card-price-wrapper">
  
                      <p class="card-price">
                        <strong>AED 1899</strong> / hour
                      </p>
  
                      <button class="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link class="btn" title="Redirect to 88Ft Luxury Yacht Dubai page" to="/yacht_luxury_88ft">Book Now</Link>
  
                    </div>
  
                  </div>

                  
  
                </div>
              </li>

              <li>
                <div class="super-yacht-card">
  
                  <figure class="card-banner">
                    <img src={yacht_90ft} title="90ft luxury yacht" alt="90ft luxury yacht" loading="lazy" width="440" height="300" class="w-100" onclick="showPopup('popup17')"/>
                </figure>
                <div id="popup17" class="popup">
                    <span class="close" onclick="closePopup('popup17')">&times;</span>
                    <img src={yacht_90ft} title="90ft luxury yacht" alt="90ft luxury yacht" loading="lazy" class="popup-image"/>
                </div>
  
                  <div class="card-content">
  
                    <div class="card-title-wrapper">
                      <h2 class="h3 card-title">
                        <Link title="Redirect to 90Ft Yacht Dubai page" to="/yacht_90ft"><strong>90Ft Yacht Dubai</strong></Link>
                      </h2>
  
                      <data class="place" value="Dubai">Dubai</data>
                    </div>
  
                    <ul class="card-list">
  
                      <li class="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span class="card-item-text">65 People</span>
                      </li>
  
                      <li class="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span class="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li class="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span class="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li class="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span class="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div class="card-price-wrapper">
  
                      <p class="card-price">
                        <strong>AED 1499</strong> / hour
                      </p>
  
                      <button class="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link class="btn" title="Redirect to 90Ft Yacht Dubai page" to="/yacht_90ft">Book Now</Link>
  
                    </div>
  
                  </div>

                  
  
                </div>
              </li>

              <li>
                <div class="super-yacht-card">
  
                  <figure class="card-banner">
                    <img src={luxury_yacht_90ft} title="90ft premium yacht" alt="90ft premium yacht" loading="lazy" width="440" height="300" class="w-100" onclick="showPopup('popup18')"/>
                </figure>
                <div id="popup18" class="popup">
                    <span class="close" onclick="closePopup('popup18')">&times;</span>
                    <img src={luxury_yacht_90ft} title="90ft premium yacht" alt="90ft premium yacht" loading="lazy" class="popup-image"/>
                </div>
  
                  <div class="card-content">
  
                    <div class="card-title-wrapper">
                      <h2 class="h3 card-title">
                        <Link title="Redirect to 90Ft Luxury Yacht Dubai page" to="/yacht_luxury_90ft"><strong>90Ft Luxury Yacht Dubai</strong></Link>
                      </h2>
  
                      <data class="place" value="Dubai">Dubai</data>
                    </div>
  
                    <ul class="card-list">
  
                      <li class="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span class="card-item-text">40 People</span>
                      </li>
  
                      <li class="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span class="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li class="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span class="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li class="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span class="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div class="card-price-wrapper">
  
                      <p class="card-price">
                        <strong>AED 1449</strong> / hour
                      </p>
  
                      <button class="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link class="btn" title="Redirect to 90Ft Luxury Yacht Dubai page" to="/yacht_luxury_90ft">Book Now</Link>
  
                    </div>
  
                  </div>

                  
  
                </div>
              </li>

              <li>
                <div class="super-yacht-card">
  
                  <figure class="card-banner">
                    <img src={vip_luxury_yacht_90ft} title="90ft vip yacht" alt="90ft vip yacht" loading="lazy" width="440" height="300" class="w-100" onclick="showPopup('popup19')"/>
                </figure>
                <div id="popup19" class="popup">
                    <span class="close" onclick="closePopup('popup19')">&times;</span>
                    <img src={vip_luxury_yacht_90ft} title="90ft vip yacht" alt="90ft vip yacht" loading="lazy" class="popup-image"/>
                </div>
  
                  <div class="card-content">
  
                    <div class="card-title-wrapper">
                      <h2 class="h3 card-title">
                        <Link title="Redirect to 90Ft Luxury Vip Yacht Dubai page" to="/yacht_luxury_vip_90ft"><strong>90Ft Luxury Vip Yacht Dubai</strong></Link>
                      </h2>
  
                      <data class="place" value="Dubai">Dubai</data>
                    </div>
  
                    <ul class="card-list">
  
                      <li class="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span class="card-item-text">50 People</span>
                      </li>
  
                      <li class="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span class="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li class="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span class="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li class="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span class="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div class="card-price-wrapper">
  
                      <p class="card-price">
                        <strong>AED 2399</strong> / hour
                      </p>
  
                      <button class="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link class="btn" title="Redirect to 90Ft Luxury Vip Yacht Dubai page" to="/yacht_luxury_vip_90ft">Book Now</Link>
  
                    </div>
  
                  </div>

                  
  
                </div>
              </li>

              <li>
                <div class="super-yacht-card">
  
                  <figure class="card-banner">
                    <img src={luxury_yacht_95ft} title="95ft luxury yacht" alt="95ft luxury yacht" loading="lazy" width="440" height="300" class="w-100" onclick="showPopup('popup20')"/>
                </figure>
                <div id="popup20" class="popup">
                    <span class="close" onclick="closePopup('popup20')">&times;</span>
                    <img src={luxury_yacht_95ft} title="95ft luxury yacht" alt="95ft luxury yacht" loading="lazy" class="popup-image"/>
                </div>
  
                  <div class="card-content">
  
                    <div class="card-title-wrapper">
                      <h2 class="h3 card-title">
                        <Link title="Redirect to 95Ft Luxury Yacht Dubai page" to="/yacht_luxury_95ft"><strong>95Ft Luxury Yacht Dubai</strong></Link>
                      </h2>
  
                      <data class="place" value="Dubai">Dubai</data>
                    </div>
  
                    <ul class="card-list">
  
                      <li class="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span class="card-item-text">50 People</span>
                      </li>
  
                      <li class="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span class="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li class="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span class="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li class="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span class="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div class="card-price-wrapper">
  
                      <p class="card-price">
                        <strong>AED 1999</strong> / hour
                      </p>
  
                      <button class="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link class="btn" title="Redirect to 95Ft Luxury Yacht Dubai page" to="/yacht_luxury_95ft">Book Now</Link>
  
                    </div>
  
                  </div>

                  
  
                </div>
              </li>

              <li>
                <div class="super-yacht-card">
  
                  <figure class="card-banner">
                    <img src={family_yacht_100ft} title="100ft luxury family yacht" alt="100ft luxury family yacht" loading="lazy" width="440" height="300" class="w-100" onclick="showPopup('popup21')"/>
                </figure>
                <div id="popup21" class="popup">
                    <span class="close" onclick="closePopup('popup21')">&times;</span>
                    <img src={family_yacht_100ft} title="100ft luxury family yacht" alt="100ft luxury family yacht" loading="lazy" class="popup-image"/>
                </div>
  
                  <div class="card-content">
  
                    <div class="card-title-wrapper">
                      <h2 class="h3 card-title">
                        <Link title="Redirect to 100Ft Family Yacht Dubai page" to="/yacht_family_100ft"><strong>100Ft Family Yacht Dubai</strong></Link>
                      </h2>
  
                      <data class="place" value="Dubai">Dubai</data>
                    </div>
  
                    <ul class="card-list">
  
                      <li class="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span class="card-item-text">80 People</span>
                      </li>
  
                      <li class="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span class="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li class="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span class="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li class="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span class="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div class="card-price-wrapper">
  
                      <p class="card-price">
                        <strong>AED 2699</strong> / hour
                      </p>
  
                      <button class="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link class="btn" title="Redirect to 100Ft Family Yacht Dubai page" to="/yacht_family_100ft">Book Now</Link>
  
                    </div>
  
                  </div>

                  
  
                </div>
              </li>

              <li>
                <div class="super-yacht-card">
  
                  <figure class="card-banner">
                    <img src={premium_luxury_yacht_101ft} title="101ft super luxury yacht" alt="101ft super luxury yacht" loading="lazy" width="440" height="300" class="w-100" onclick="showPopup('popup22')"/>
                </figure>
                <div id="popup22" class="popup">
                    <span class="close" onclick="closePopup('popup22')">&times;</span>
                    <img src={premium_luxury_yacht_101ft} title="101ft super luxury yacht" alt="101ft super luxury yacht" loading="lazy" class="popup-image"/>
                </div>
  
                  <div class="card-content">
  
                    <div class="card-title-wrapper">
                      <h2 class="h3 card-title">
                        <Link title="Redirect to 101Ft Premium Luxury Yacht Dubai page" to="/yacht_premium_luxury_101ft"><strong>101Ft Premium Luxury Yacht Dubai</strong></Link>
                      </h2>
  
                      <data class="place" value="Dubai">Dubai</data>
                    </div>
  
                    <ul class="card-list">
  
                      <li class="card-list-item">
                        <ion-icon name="people-outline"></ion-icon>
  
                        <span class="card-item-text">65 People</span>
                      </li>
  
                      <li class="card-list-item">
                        <ion-icon name="add-outline"></ion-icon>
                        <span class="card-item-text">AddOn Food Items</span>
                      </li>
                      
  
                      <li class="card-list-item">
                        <ion-icon name="beer-outline"></ion-icon>
                        <span class="card-item-text">Drinks and Snacks</span>
                      </li>
                      
                      
                      
  
                      <li class="card-list-item">
                        <ion-icon name="close-outline"></ion-icon>
                        <span class="card-item-text">Free Cancellation</span>
                      </li>
  
                    </ul>
  
                    <div class="card-price-wrapper">
  
                      <p class="card-price">
                        <strong>AED 2499</strong> / hour
                      </p>
  
                      <button class="btn fav-btn" aria-label="Add to favourite list">
                        <ion-icon name="heart-outline"></ion-icon>
                      </button>
  
                      <Link class="btn" title="Redirect to 101Ft Premium Luxury Yacht Dubai page" to="/yacht_premium_luxury_101ft">Book Now</Link>
  
                    </div>
  
                  </div>

                  
  
                </div>
              </li>


          </ul>

        </div>
      </section>

      

      <section class="section get-start">
        <div class="container">

          <h2 class="h2 section-title"><strong>Book a Yacht in Dubai for Party</strong> in just four simple steps</h2>

          <ul class="get-start-list">

            <li>
              <div class="get-start-card">

                <div class="card-icon icon-1">
                  <ion-icon name="search-outline"></ion-icon>
                </div>

                <h3 class="card-title">Search for a Yacht</h3>

                <p class="card-text">
                  Search for the <strong>yacht </strong>in Dubai from the group of listings
                </p>

                <Link title="" to="#super-yacht" class="card-link">Get started</Link>

              </div>
            </li>

            <li>
              <div class="get-start-card">

                <div class="card-icon icon-2">
                  <ion-icon name="boat-outline"></ion-icon>
                </div>

                <h3 class="card-title">Choose a Yacht</h3>

                <p class="card-text">
                  Choose the <strong>best luxury yacht </strong>in Dubai that particularly attracts you the most 
                </p>

              </div>
            </li>

            <li>
              <div class="get-start-card">

                <div class="card-icon icon-3">
                  <ion-icon name="checkmark-outline"></ion-icon>
                </div>

                <h3 class="card-title">Check the Description</h3>

                <p class="card-text">
                  Confirm the yacht by checking if the description includes the expected features
                </p>

              </div>
            </li>

            <li>
              <div class="get-start-card">

                <div class="card-icon icon-4">
                  <ion-icon name="call"></ion-icon>
                </div>

                <h3 class="card-title">Make a deal</h3>

                <p class="card-text">
                  Click 'Rent Now' option below the yacht to finalize your deal on WhatsApp
                </p>

              </div>
            </li>

          </ul>

        </div>
      </section>
    
      
      </article>
      </main>




  <Footer/>

</div>


);
}

export default YachtRental;