import React from 'react';
import { Helmet } from 'react-helmet';
import '../assets/css/yacht_styles.css';
import favicon from '../assets/images/favicon.webp';
import { Link } from 'react-router-dom';
import Header from '../Header';
import ScrollArrows from '../ScrollArrows';
import yacht_azimut_55ft from'../assets/images/yacht-images/55ft-azimut-yacht-dubai-dubai-marina-yacht-rental/55ft-azimut-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_azimut_55ft_hall from'../assets/images/yacht-images/55ft-azimut-yacht-dubai-dubai-marina-yacht-rental/55ft-yacht-hall.webp'
import yacht_azimut_55ft_bedroom from'../assets/images/yacht-images/55ft-azimut-yacht-dubai-dubai-marina-yacht-rental/55ft-yacht-bedroom.webp';
import yacht_azimut_55ft_terrace from'../assets/images/yacht-images/55ft-azimut-yacht-dubai-dubai-marina-yacht-rental/55ft-yacht-open terrace.webp';
import yacht_azimut_55ft_balcony from'../assets/images/yacht-images/55ft-azimut-yacht-dubai-dubai-marina-yacht-rental/55ft-yacht-balcony-sofa.webp';
import yacht_azimut_55ft_washbasin from'../assets/images/yacht-images/55ft-azimut-yacht-dubai-dubai-marina-yacht-rental/55ft-yacht-washbasin.webp';
import YachtImageShowcase from './YachtImageShowcase';
import Footer from '../Footer';

function Yacht55ft() {
  const hoverImages = [
    { src: yacht_azimut_55ft, alt: "55ft azimut yacht in dubai" },
    { src: yacht_azimut_55ft_hall, alt: "55ft azimut yacht hall in dubai" },
    { src: yacht_azimut_55ft_bedroom, alt: "55ft azimut yacht bedroom in dubai" },
    { src: yacht_azimut_55ft_terrace, alt: "55ft azimut yacht open terrace in dubai" },
    { src: yacht_azimut_55ft_balcony, alt: "55ft azimut yacht balcony with in dubai" },
  ];

    return (

<div>

<Helmet>

<title>55Ft Azimut Yacht for Rent - Book Online</title>
<meta name="description" content="Discover the best 55ft azimut yacht for rent in Dubai, accommodating up to 14 guests, for AED 39 Dirhams) per person including meals. Book online and get an extra discount"/>
<meta name="keywords" content="book dubai yacht, 55ft yacht dubai, mini yacht dubai, small yacht dubai, dubai marina yacht, yacht rental dubai, dubai yacht ride" />
<link rel="canonical" href="https://dubaiyachtevents.com/yacht_azimut_55ft"/>
<link rel="icon" title="" href={favicon} type="image/x-icon"></link>
      <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "WebPage",
          "@id": "https://dubaiyachtevents.com/yacht_azimut_55ft",
          "url": "https://dubaiyachtevents.com/yacht_azimut_55ft",
          "name": "55Ft Azimut Yacht for Rent - Book Online",
          "description": "Discover the best 55ft azimut yacht for rent in Dubai, accommodating up to 14 guests, for AED 39 Dirhams) per person including meals. Book online and get an extra discount",
          "publisher": {
            "@type": "Organization",
            "name": "Dubai Yacht Events",
            "logo": {
              "@type": "ImageObject",
              "url": "https://dubaiyachtevents.com/static/media/logo.b4a5c51688c411ee299c.webp"
            },
            "url": "https://dubaiyachtevents.com/",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+971545619773",
              "contactType": "Customer Service",
              "areaServed": ["EN", "AE", "HI", "ML", "TA"]
            }
          }
        },
        {
          "@type": "WebSite",
          "name": "Dubai Yacht Events",
          "alternateName": "Dubai Yacht Ride",
          "url": "https://dubaiyachtevents.com/",
          "potentialAction": {
            "@type": "SearchAction",
            "target": {
              "@type": "EntryPoint",
              "urlTemplate": "https://query.dubaiyachtevents.com/search?q={search_term_string}"
            },
            "query-input": "required name=search_term_string"
          }
        },
        {
          "@type": "Product",
          "name": "55Ft Azimut Yacht Dubai-Dubai Marina Yacht Rental",
          "description": "Discover the best 55ft azimut yacht for rent in Dubai, accommodating up to 14 guests, for AED 39 Dirhams) per person including meals. Book online and get an extra discount",
          "sku": "YACHT008",
          "brand": {
            "@type": "Brand",
            "name": "Dubai Yacht Events"
          },
          "offers": {
            "@type": "Offer",
            "price": "449.00",
            "priceCurrency": "AED",
            "priceValidUntil": "2024-12-31",
            "availability": "https://schema.org/InStock",
            "url": "https://dubaiyachtevents.com/yacht_azimut_55ft",
            "seller": {
              "@type": "Organization",
              "name": "Dubai Yacht Events"
            }
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.9",
            "reviewCount": "14598"
          },
          "image": [
            "https://dubaiyachtevents.com/static/media/55ft-azimut-yacht-dubai-dubai-marina-yacht-rental.bdb44cba0c3db19a13e2.webp",
            "https://dubaiyachtevents.com/static/media/55ft-yacht-hall.df8622aef780c9814f35.webp",
            "https://dubaiyachtevents.com/static/media/55ft-yacht-bedroom.e2bd417d0dea7f042a83.webp",
            "https://dubaiyachtevents.com/static/media/55ft-yacht-open%20terrace.857e718fc4223aa485f7.webp",
            "https://dubaiyachtevents.com/static/media/55ft-yacht-balcony-sofa.6ae3a8cb9cee37b49e0a.webp"
          ]
        },
        {
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "How to rent 55ft yacht in dubai?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Contact our support(dubaiyachtevents.com) via call or watsapp then Confirm the price and book you slot"
              }
            },
            {
              "@type": "Question",
              "name": "Where i can ride this 55 ft yacht in dubai?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "This 55ft yacht is available in dubai marina.You can start your ride from dubai marina."
              }
            },
            {
              "@type": "Question",
              "name": "how many person can travel in 55ft yacht in dubai?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Maximum 14 person can travel per ride in 55 ft yacht in dubai"
              }
            },
            {
              "@type": "Question",
              "name": "How to book 55ft yacht in dubai marina?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "dubaiyachtevents.com is the best website to book yacht in Dubai with 0% commission."
              }
            },
            {
              "@type": "Question",
              "name": "What is the price for the 55ft yacht in dubai?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The price for the 55ft yacht in dubai is approximately AED 449"
              }
            }
          ]
        },
        {
          "@type": "Place",
          "name": "Dubai Marina",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Dubai Marina",
            "addressLocality": "Dubai",
            "addressRegion": "Dubai",
            "addressCountry": "AE"
          }
        },
        {
          "@type": "LocalBusiness",
          "name": "Dubai Yacht Events",
          "image": "https://dubaiyachtevents.com/static/media/logo.b4a5c51688c411ee299c.webp",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Dubai Marina",
            "addressLocality": "Dubai",
            "addressRegion": "Dubai",
            "addressCountry": "AE"
          },
          "url": "https://dubaiyachtevents.com/",
          "telephone": "+971545619773",
          "priceRange": "449 AED",
          "sameAs": [
          "https://m.facebook.com/vcyachtsdubai/",
          "https://www.instagram.com/boatrental_dubai?igsh=MTUxYW1oeDJoMXR2Mg==",
          "https://pin.it/2CuYXWb",
          "https://x.com/boatrentdubai?t=SmkLRKWMHCWGQMhQJ55UtQ&s=09",
          "https://www.youtube.com/channel/UC9SsG2PjxUsKrMc9QdhuhRQ",
          "https://www.linkedin.com/in/boat-rental-dubai-6391b5256/",
          "https://boatrentaldubaimarina.blogspot.com/"
          ],
          "openingHoursSpecification": [
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday"
              ],
              "opens": "00:00",
              "closes": "23:59"
            }
          ]
        }
      ]
    })}
    </script>
    <script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 3,
        "name": "Yachts For Rent",
        "item": "https://dubaiyachtevents.com/yacht_rental"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Boats For Rent",
        "item": "https://dubaiyachtevents.com/boat_rental_dubai"
      },
      {
        "@type": "ListItem",
        "position": 4,
        "name": "WaterSports",
        "item": "https://dubaiyachtevents.com/#location"
      },
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Dubai Events",
        "item": "https://dubaiyachtevents.com/"
      }
    ]
  })}
  </script>



     
     </Helmet>

<Header/>
<ScrollArrows/>


    <div className = "main-wrapper">
        <div className = "container">
            <div className = "product-div">
                <div className = "product-div-left">
                <YachtImageShowcase 
                     mainImage={yacht_azimut_55ft} 
                     mainAlt="55ft azimut yacht in dubai" 
                     hoverImages={hoverImages} 
                   />
                </div>
                <div className = "product-div-right">
                    <span className = "product-name">55Ft Azimut Yacht Dubai-Dubai Marina Yacht Rental</span>
                    <span className = "product-price">AED 549</span>
                    <div className = "product-rating">
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star-half-alt"></i></span>
                        <span>(1562 ratings)</span>
                    </div>
                    <p className = "product-description"><ul>
                        
                        <li>1. <b>Crew:</b>We have an individual crew for each yacht so that they can provide you with the exact features of the yacht and guide you properly</li>
                        <li>2. <b>Entertainment:</b> Many games with DJ music system</li>     
                        <li>3. <b>Fishing:</b> Fishing equipment is provided. Enjoy double the fun: catch the fish and cook it.</li>
                        <li>4. <b>Swimming:</b> Swimming facilities are available with the help of the crew if needed</li>
                        <li>5. <b>Starters:</b>Start your journey with starters like soft drinks</li>
                        <li>6. <b>Safety:</b>Life jackets for all passengers. Safety is the top priority in Dubai, and we provide it according to rules and regulations in Dubai.</li>
                        <li>7. <b>Food:</b>Order your favorite food anytime during your ride</li>
                        
                    </ul></p>
                    <div className="btn-groups">
                        <Link to="https://wa.me/971545619773?text=Hi%20DubaiYachtEvents,%20I%20am%20interested%20in%20your%2055ft%20Azimut%20Star%20Yacht.%20Can%20we%20finalize%20our%20deal%3F" className="add-cart-btn"><i className="fas fa-info-circle"></i> Enquiry</Link>
                        <Link to="https://wa.me/971545619773?text=Hi%20DubaiYachtEvents,%20I%20am%20interested%20in%20your%2055ft%20Azimut%20Star%20Yacht.%20Can%20we%20finalize%20our%20deal%3F" className="buy-now-btn"><i className="fas fa-calendar-alt"></i> Rent Now</Link>
                    </div>
                    <div className = "social-links">
                      <p>Share At: </p>
                      <Link to = "#">
                        <i className = "fab fa-facebook-f"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-twitter"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-instagram"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-whatsapp"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-pinterest"></i>
                      </Link>
                    </div>
                    
                    
                    
                </div>
                <div style={{marginLeft: '10%' , marginRight: '10%'}}><h1 >Description</h1><br/>
                <p style={{marginTop: '2%'}}>1. The maximum number of 14  people allowed to travel in this yacht per ride.</p>
                <p style={{marginTop: '2%'}}>2. We provide the best flexible timings for customers. You can choose the number of hours you want to travel.</p>
                <img className="desc-img" src={yacht_azimut_55ft_bedroom} alt="55ft azimut yacht bedroom in dubai" onclick="openModal('modal1')"/>
              
                  <div id="modal1" className="modal">
                      <div className="modal-content">
                          <span className="close" onclick="closeModal('modal1')">&times;</span>
                          <img src={yacht_azimut_55ft_bedroom} alt="55ft azimut yacht bedroom in dubai" style={{width: '100%'}}/>
                      </div>
                  </div>
                <p style={{marginTop: '2%'}}>3. When the ride starts, we provide soft drinks.</p>
                <img className="desc-img" src={yacht_azimut_55ft_washbasin} alt="55ft azimut yacht washbasin in dubai" onclick="openModal('modal2')"/>
              
                  <div id="modal2" className="modal">
                      <div className="modal-content">
                          <span className="close" onclick="closeModal('modal2')">&times;</span>
                          <img src={yacht_azimut_55ft_washbasin} alt="55ft azimut yacht washbasin in dubai" style={{width: '100%'}}/>
                      </div>
                  </div>
                <p style={{marginTop: '2%'}}>4. We provide free cancellation only for customers who book online</p>
                <p style={{marginTop: '2%'}}>5. It is fully covered with Air cooler ,high quality DJ sound system and high speed etisalat wifi, colorfull LED lightings etc.</p>
                <p style={{marginTop: '2%'}}>6.Azimut is a popular series, so don't wait rent before it's out of stock.</p>
                <img className="desc-img" src={yacht_azimut_55ft_hall} alt="55ft azimut yacht hall in dubai" onclick="openModal('modal3')"/>
              
                  <div id="modal3" className="modal">
                      <div className="modal-content">
                          <span className="close" onclick="closeModal('modal3')">&times;</span>
                          <img src={yacht_azimut_55ft_hall} alt="55ft azimut yacht hall in dubai" style={{width: '100%'}}/>
                      </div>
                  </div>
                <p style={{marginTop: '2%'}}>7. We provide the best safety and privacy in terms of competitors.Check Our 100% orginal reviews.</p>
                <p style={{marginTop: '2%'}}>8. Images provided are only for reference. In reality, it is much bigger than the image; you can't imagine.</p>

            </div>
        </div>
            
        </div>
        
    </div>
    

    <Footer/>


</div>

);
}

export default Yacht55ft;