import React from 'react';
import { Helmet } from 'react-helmet';
import '../assets/css/yacht_styles.css';
import favicon from '../assets/images/favicon.webp';
import { Link } from 'react-router-dom';
import Header from '../Header';
import ScrollArrows from '../ScrollArrows';
import yacht_80ft from'../assets/images/yacht-images/80ft-yacht-dubai-dubai-marina-yacht-rental/80ft-yacht-dubai-dubai-marina-yacht-rental.webp';
import yacht_80ft_hall from'../assets/images/yacht-images/80ft-yacht-dubai-dubai-marina-yacht-rental/80-ft-sea-quest-yacht-hall.webp'
import yacht_80ft_bedroom from'../assets/images/yacht-images/80ft-yacht-dubai-dubai-marina-yacht-rental/80-ft-sea-quest-yacht-bedroom.webp';
import yacht_80ft_2nd_balcony from'../assets/images/yacht-images/80ft-yacht-dubai-dubai-marina-yacht-rental/80-ft-sea-quest-yacht-balcony.webp';
import yacht_80ft_dining from'../assets/images/yacht-images/80ft-yacht-dubai-dubai-marina-yacht-rental/80-ft-sea-quest-yacht-dining-hall.webp';
import yacht_80ft_sofa from'../assets/images/yacht-images/80ft-yacht-dubai-dubai-marina-yacht-rental/80-ft-sea-quest-yacht-front-seater-sofa.webp';
import yacht_80ft_balcony from'../assets/images/yacht-images/80ft-yacht-dubai-dubai-marina-yacht-rental/80-ft-sea-quest-yacht-balcony.webp';
import YachtImageShowcase from './YachtImageShowcase';
import Footer from '../Footer';



function Yacht80ft() {

 const hoverImages = [
    { src: yacht_80ft, alt: "80ft super luxury yacht in Dubai" },
    { src: yacht_80ft_bedroom, alt: "80ft super luxury yacht bedroom in Dubai" },
    { src: yacht_80ft_2nd_balcony, alt: "80ft super luxury yacht balcony in Dubai" },
    { src: yacht_80ft_dining, alt: "80ft super luxury yacht dining hall in Dubai" },
    { src: yacht_80ft_sofa, alt: "80ft super luxury yacht portico with sofa in Dubai" },
  ];
  
    return (

<div>

<Helmet>

<title>80Ft Yacht for Rent - Book Online</title>
<meta name="description" content="Discover the best 80ft yacht for rent in Dubai, accommodating up to 35 guests, for AED 35 (Dirhams) per person including meals. Book online and get an extra discount"/>
<meta name="keywords" content="book dubai yacht, 80ft yacht dubai, mini yacht dubai, small yacht dubai, dubai marina yacht, yacht rental dubai, dubai yacht ride" />
<link rel="canonical" href="https://dubaiyachtevents.com/yacht_80ft"/>
<link rel="icon" title="" href={favicon} type="image/x-icon"></link>
      <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "WebPage",
          "@id": "https://dubaiyachtevents.com/yacht_80ft",
          "url": "https://dubaiyachtevents.com/yacht_80ft",
          "name": "80Ft Yacht for Rent - Book Online",
          "description": "Discover the best 80ft yacht for rent in Dubai, accommodating up to 35 guests, for AED 35 (Dirhams) per person including meals. Book online and get an extra discount",
          "publisher": {
            "@type": "Organization",
            "name": "Dubai Yacht Events",
            "logo": {
              "@type": "ImageObject",
              "url": "https://dubaiyachtevents.com/static/media/logo.b4a5c51688c411ee299c.webp"
            },
            "url": "https://dubaiyachtevents.com/",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+971545619773",
              "contactType": "Customer Service",
              "areaServed": ["EN", "AE", "HI", "ML", "TA"]
            }
          }
        },
        {
          "@type": "WebSite",
          "name": "Dubai Yacht Events",
          "alternateName": "Dubai Yacht Ride",
          "url": "https://dubaiyachtevents.com/",
          "potentialAction": {
            "@type": "SearchAction",
            "target": {
              "@type": "EntryPoint",
              "urlTemplate": "https://query.dubaiyachtevents.com/search?q={search_term_string}"
            },
            "query-input": "required name=search_term_string"
          }
        },
        {
          "@type": "Product",
          "name": "80Ft Yacht Dubai-Dubai Marina Yacht Rental",
          "description": "Discover the best 80ft yacht for rent in Dubai, accommodating up to 35 guests, for AED 35 (Dirhams) per person including meals. Book online and get an extra discount",
          "sku": "YACHT0015",
          "brand": {
            "@type": "Brand",
            "name": "Dubai Yacht Events"
          },
          "offers": {
            "@type": "Offer",
            "price": "999.00",
            "priceCurrency": "AED",
            "priceValidUntil": "2024-12-31",
            "availability": "https://schema.org/InStock",
            "url": "https://dubaiyachtevents.com/yacht_80ft",
            "seller": {
              "@type": "Organization",
              "name": "Dubai Yacht Events"
            }
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.9",
            "reviewCount": "45698"
          },
          "image": [
            "https://dubaiyachtevents.com/static/media/80ft-yacht-dubai-dubai-marina-yacht-rental.900de637a39c772aee59.webp",
            "https://dubaiyachtevents.com/static/media/80-ft-sea-quest-yacht-bedroom.ab82dba08437b5969cbe.webp",
            "https://dubaiyachtevents.com/static/media/80-ft-sea-quest-yacht-balcony.cf76cf14b9915fa88f4c.webp",
            "https://dubaiyachtevents.com/static/media/80-ft-sea-quest-yacht-dining-hall.7f03783d8520de51caf0.webp",
            "https://dubaiyachtevents.com/static/media/80-ft-sea-quest-yacht-front-seater-sofa.01cfcac6798798886ba7.webp"
          ]
        },
        {
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "How to rent 80ft Yacht in dubai?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Contact our support(dubaiyachtevents.com) via call or watsapp then Confirm the price and book you slot"
              }
            },
            {
              "@type": "Question",
              "name": "Where i can ride this 80ft Yacht in dubai?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "This 80ft Yacht is available in dubai marina.You can start your ride from dubai marina."
              }
            },
            {
              "@type": "Question",
              "name": "how many person can travel in 80ft Yacht in dubai?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Maximum 35 person can travel per ride in 80 ft yacht in dubai"
              }
            },
            {
              "@type": "Question",
              "name": "How to book 80ft Yacht in dubai marina?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "dubaiyachtevents.com is the best website to book yacht in Dubai with 0% commission."
              }
            },
            {
              "@type": "Question",
              "name": "What is the price for the 80ft Yacht in dubai?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The price for the 80ft Yacht in dubai is approximately AED 999"
              }
            }
          ]
        },
        {
          "@type": "Place",
          "name": "Dubai Marina",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Dubai Marina",
            "addressLocality": "Dubai",
            "addressRegion": "Dubai",
            "addressCountry": "AE"
          }
        },
        {
          "@type": "LocalBusiness",
          "name": "Dubai Yacht Events",
          "image": "https://dubaiyachtevents.com/static/media/logo.b4a5c51688c411ee299c.webp",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Dubai Marina",
            "addressLocality": "Dubai",
            "addressRegion": "Dubai",
            "addressCountry": "AE"
          },
          "url": "https://dubaiyachtevents.com/",
          "telephone": "+971545619773",
          "priceRange": "999 AED",
          "sameAs": [
          "https://m.facebook.com/vcyachtsdubai/",
          "https://www.instagram.com/boatrental_dubai?igsh=MTUxYW1oeDJoMXR2Mg==",
          "https://pin.it/2CuYXWb",
          "https://x.com/boatrentdubai?t=SmkLRKWMHCWGQMhQJ55UtQ&s=09",
          "https://www.youtube.com/channel/UC9SsG2PjxUsKrMc9QdhuhRQ",
          "https://www.linkedin.com/in/boat-rental-dubai-6391b5256/",
          "https://boatrentaldubaimarina.blogspot.com/"
          ],
          "openingHoursSpecification": [
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday"
              ],
              "opens": "00:00",
              "closes": "23:59"
            }
          ]
        }
      ]
    })}
    </script>
    <script type="application/ld+json">
  {JSON.stringify({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 3,
        "name": "Yachts For Rent",
        "item": "https://dubaiyachtevents.com/yacht_rental"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Boats For Rent",
        "item": "https://dubaiyachtevents.com/boat_rental_dubai"
      },
      {
        "@type": "ListItem",
        "position": 4,
        "name": "WaterSports",
        "item": "https://dubaiyachtevents.com/#location"
      },
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Dubai Events",
        "item": "https://dubaiyachtevents.com/"
      }
    ]
  })}
  </script>


     
     </Helmet>

<Header/>

<ScrollArrows/>

    <div className = "main-wrapper">
        <div className = "container">
            <div className = "product-div">
                <div className = "product-div-left">
                    
                    <YachtImageShowcase 
                     mainImage={yacht_80ft} 
                     mainAlt="80ft super luxury yacht in Dubai" 
                     hoverImages={hoverImages} 
                   />
                </div>
                <div className = "product-div-right">
                    <span className = "product-name">80Ft Yacht Dubai-Dubai Marina Yacht Rental</span>
                    <span className = "product-price">AED 1199</span>
                    <div className = "product-rating">
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star"></i></span>
                        <span><i className = "fas fa-star-half-alt"></i></span>
                        <span>(4589 ratings)</span>
                    </div>
                    <p className = "product-description"><ul>
                        
                            <li>1. <b>Crew:</b>  Our crew give full support for entire ride</li>
                            <li>2. <b>Entertainment:</b> This is a luxury yacht focused on design, especially for parties like client meetings, business success celebrations, etc.</li>
                            <li>3. <b>Fishing:</b> Fishing facilities are also available, and we provide equipment</li>
                            <li>4. <b>Swimming:</b> Swimming and diving options are available</li>
                            <li>4. <b>Starters:</b>  Juice will be given for all passengers</li>
                            <li>5. <b>Safety:</b>  We provide privacy for your ride, so don't hesitate</li>
                            <li>6. <b>Food:</b>  You can order your favorite meals at any time during your trip. Enjoy your dining experience!</li>
                        
                        
                    </ul></p>
                    <div className="btn-groups">
                        <Link to="https://wa.me/971545619773?text=Hi%20DubaiYachtEvents,%20I%20am%20interested%20in%20your%2080ft%20Sea%20Quest%20Yacht.%20Can%20we%20finalize%20our%20deal%3F" className="add-cart-btn"><i className="fas fa-info-circle"></i> Enquiry</Link>
                        <Link to="https://wa.me/971545619773?text=Hi%20DubaiYachtEvents,%20I%20am%20interested%20in%20your%2080ft%20Sea%20Quest%20Yacht.%20Can%20we%20finalize%20our%20deal%3F" className="buy-now-btn"><i className="fas fa-calendar-alt"></i> Rent Now</Link>
                    </div>
                    <div className = "social-links">
                      <p>Share At: </p>
                      <Link to = "#">
                        <i className = "fab fa-facebook-f"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-twitter"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-instagram"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-whatsapp"></i>
                      </Link>
                      <Link to = "#">
                        <i className = "fab fa-pinterest"></i>
                      </Link>
                    </div>
                    
                    
                    
                </div>
                <div style={{marginLeft: '10%' , marginRight: '10%'}}><h1 >Description</h1><br/>
                <p style={{marginTop: '2%'}}>1. A maximum of 35 members can travel per ride.</p>
                <p style={{marginTop: '2%'}}>2. This is a luxury yacht. Most yachts in this price range offer a luxury experience</p>
                <img className="desc-img" src={yacht_80ft_hall} alt="80ft super luxury yacht hall in dubai" onclick="openModal('modal1')"/>
              
                  <div id="modal1" className="modal">
                      <div className="modal-content">
                          <span className="close" onclick="closeModal('modal1')">&times;</span>
                          <img src={yacht_80ft_hall} alt="80ft super luxury yacht hall in dubai" style={{width: '100%'}}/>
                      </div>
                  </div>
                <p style={{marginTop: '2%'}}>3. The 80Ft Yacht Dubai-Dubai Marina Yacht Rental is a luxury yacht. You can see the images provided; it looks neat and offers a luxury experience.</p>
                <img className="desc-img" src={yacht_80ft_bedroom} alt="80ft super luxury yacht bedroom in dubai" onclick="openModal('modal2')"/>
              
                  <div id="modal2" className="modal">
                      <div className="modal-content">
                          <span className="close" onclick="closeModal('modal2')">&times;</span>
                          <img src={yacht_80ft_bedroom} alt="80ft super luxury yacht bedroom in dubai" style={{width: '100%'}}/>
                      </div>
                  </div>
                <p style={{marginTop: '2%'}}>4. We provide free cancellation only for customers who book online</p>
                <p style={{marginTop: '2%'}}>5. This is an Luxury yacht so we focused on luxury like better lightings,high and crystal clear sound dj system,And coverd with Ac</p>
                <p style={{marginTop: '2%'}}>6. It has two large bedrooms with high-quality mattresses for cushioning</p>
                <p style={{marginTop: '2%'}}>7. If you want luxury experience , don't waste your money booking rooms. Book the 80Ft Yacht Dubai-Dubai Marina Yacht Rental</p>
                <img className="desc-img" src={yacht_80ft_balcony} alt="80ft super luxury yacht balcony in dubai" onclick="openModal('modal3')"/>
              
                  <div id="modal3" className="modal">
                      <div className="modal-content">
                          <span className="close" onclick="closeModal('modal3')">&times;</span>
                          <img src={yacht_80ft_balcony} alt="80ft super luxury yacht balcony in dubai" style={{width: '100%'}}/>
                      </div>
                  </div>
                <p style={{marginTop: '2%'}}>8. Images provided are only for reference. In reality, it is much bigger than the image; you can't imagine.</p>




            </div>
        </div>
            
        </div>
        
    </div>
    

    <Footer/>


</div>

);
}

export default Yacht80ft;